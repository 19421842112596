import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'line_bot_accounts',
  attributes: ['id', 'created_at', 'updated_at'],
  editableAttributes: []
}

export default class LineBotAccount extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }
}
