<template lang="pug">
.vc-page-preference-select-sponsors-container(v-show="isShowContainer")
  .odd-container
    k-section-header(:header-info="headerInfo")
  .section-body.info-link
    select-sponsors.odd-container(:promoter-share-ids="promoterShareIds")
</template>

<script setup lang="ts">
import { computed } from 'vue'
import KSectionHeader from '@applicationComponents/common/k-section-header.vue'
import SelectSponsors from '@applicationComponents/sponsor/select-sponsors.vue'

interface Data {
  title: String
  title_en: String
  subtitle: String
  promoter_share_ids: Array<String>
}

// props
const props = defineProps<{
  data: Data
}>()

// computed
/**
 * @returns {computedRef<Object>}
 */
const headerInfo = computed<Object>(() => {
  return {
    title: props.data.title,
    titleEn: props.data.title_en,
    subtitle: props.data.subtitle
  }
})
/**
 * @returns {computedRef<Array<String>>}
 */
const promoterShareIds = computed<Array<String>>(() => {
  return props.data.promoter_share_ids
})
/**
 * @returns {computedRef<Boolean>}
 */
const isShowContainer = computed<Boolean>(() => {
  return promoterShareIds.value.length > 0
})
</script>
