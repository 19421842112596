<template lang="pug">
.vc-page-preference-customer-success-department-container
  .odd-container
    .odd-section-header.-is-left
      .section-title.-is-large
        .text {{ headerInfo.title }}
      .section-subtitle.-is-size-large.-is-bold.-is-odd
        .text {{ headerInfo.subtitle }}
  .section-body
    .odd-container
      k-customer-success-department
</template>

<script setup lang="ts">
import { computed } from 'vue'
import kCustomerSuccessDepartment from '@applicationComponents/common/k-customer-success-department.vue'

interface Data {
  title: String
  subtitle: String
}

// props
const props = defineProps<{
  data: Data
}>()

// computed
/**
 * @returns {computedRef<Object>}
 */
const headerInfo = computed<Object>(() => {
  return {
    title: props.data.title,
    subtitle: props.data.subtitle
  }
})
</script>
