import EditorContentWrapper from '../../components/promotional_article/editor-content-wrapper.vue'

export default {
  name: 'select-mg-show-page-container',

  components: {
    EditorContentWrapper
  }

  // mixins: [],

  // props: {},

  // data() {
  //   return {
  //   }
  // },

  // computed: {},

  // created() {},

  // mounted() {}

  // methods: {}
}
