import moment from 'moment'
import localForage from 'localforage'
import { v4 as uuidv4 } from 'uuid'

const IMPRESSION_KEY = 'impression-config'

export default ({ impressionableType, impressionableId, store }) => {
  const createImpression = async () => {
    const config = await getOrInitConfig()

    if (hasImpressionRecord(config)) return

    await store.dispatch('impressions/create', {
      impressionableType: impressionableType,
      impressionableId: impressionableId,
      uuid: config.id
    })

    saveImpressionRecord(config)
  }

  const getConfig = () => {
    return localForage.getItem(IMPRESSION_KEY)
  }

  const clearConfig = () => {
    return localForage.removeItem(IMPRESSION_KEY)
  }

  // ============ private methods ================

  const getOrInitConfig = async () => {
    let config = await getConfig()

    if (!config || isConfigExpired(config)) config = initConfig()

    return config
  }

  const initConfig = async () => {
    const config = {
      id: generateUUID(),
      exp: moment().add(1, 'day').startOf('day').unix(),
      impressionRecords: []
    }

    await localForage.setItem(IMPRESSION_KEY, config)

    return config
  }

  const isConfigExpired = (config) => {
    return moment().unix() > parseInt(config.exp)
  }

  const generateUUID = () => {
    return uuidv4()
  }

  const hasImpressionRecord = (config) => {
    return !!config.impressionRecords.find((record) => {
      return (
        record.impressionableType === impressionableType &&
        record.impressionableId === parseInt(impressionableId)
      )
    })
  }

  const saveImpressionRecord = (config) => {
    config.impressionRecords.push({
      impressionableType: impressionableType,
      impressionableId: parseInt(impressionableId)
    })

    localForage.setItem(IMPRESSION_KEY, config)
  }

  return {
    createImpression,
    getConfig,
    clearConfig
  }
}
