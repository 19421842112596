<template lang="pug">
form.vc-order-cvs-selector.vc-order-address-form(
  v-if="cvsStoreInfo && isLoaded"
  @submit.prevent=""
)
  .section-label
    //- span {{ copyLocaleText('cvs_information') }}
    span {{ shippingAddressHelper.partner.name }}
    span {{ shippingAddressHelper.shippingMethod.name }}

  .section-label
    span
    common-address-button(
      v-if="commonAddressHelper.isCommonAddresButtonShow"
      :address="shipmentForm.address"
      :shipping-type="shippingAddressHelper.shippingType"
      @selected-address="commonAddressHelper.selectCommonAddressHandler"
    )

  .form-section.has-sub-fields
    b-field(
      :label="attributeLocaleText('user/common_address', 'recipient')"
      :type="errors.errorClassAt('addr_recipient')"
      :message="errors.get('addr_recipient')"
    )
      b-input(
        type="text"
        name="name"
        autocomplete="name"
        v-model="shipmentForm.address.recipient"
        @input="errors.clear('addr_recipient')"
      )
    b-field(
      :label="attributeLocaleText('user/common_address', 'phone')"
      :type="errors.errorClassAt('addr_phone')"
      :message="errors.get('addr_phone')"
    )
      b-input(
        type="tel"
        autocomplete="tel"
        v-model="shipmentForm.address.phone"
        @input="errors.clear('addr_phone')"
      )

    save-address-as-common-field(
      v-if="commonAddressHelper.isSaveAddressAsCommonShow"
      :save-address-as-common.sync="shipmentForm.save_shipping_address_as_common"
    )

  .form-section
    p {{ cvsStoreInfo.cvs_store_name }}
    p {{ cvsStoreInfo.cvs_address }}
    p {{ cvsStoreInfo.cvs_telephone }}

    b-field(
      :type="errors.errorClassAt('addr_cvs_store_info')"
      :message="errors.get('addr_cvs_store_info')"
    )

    button.button.is-odd.is-outlined(
      href="#"
      target="_blank"
      ref="noopenner noreferrer"
      @click.prevent="getParamsAndGoForSelect()"
    )
      | {{ selectCvsBtnText }}
</template>

<script>
import queryString from 'query-string'
import expressMapSelectHelpersMixin from '@sharedMixins/express_map_select_helpers_mixin.js'
import useShippingAddressHelper from './shipping-address-helper'
import useCommonAddressHelper from './common-address-helper'
import CommonAddressButton from './common-address-button.vue'
import SaveAddressAsCommonField from './save-address-as-common-field.vue'

export default {
  components: {
    CommonAddressButton,
    SaveAddressAsCommonField
  },

  mixins: [expressMapSelectHelpersMixin],

  props: {
    shipmentForm: {
      type: Object,
      required: true
    },

    firstSameDestinationShipmentForm: {
      type: Object,
      required: true
    },

    cartService: {
      type: Object,
      required: true
    },

    storeFormData: {
      type: Function,
      required: false
    }
  },

  data() {
    return {
      shippingAddressHelper: null,
      commonAddressHelper: null,
      isLoaded: false
    }
  },

  computed: {
    orderShipment() {
      return this.$store.getters['orderShipments/find'](this.shipmentForm.id)
    },

    order() {
      return this.$store.getters['orders/find'](this.orderShipment.order.id)
    },

    selectCvsBtnText() {
      return this.actionLocaleText('select_x', {
        x: this.enumLocaleText(
          'shipping_method',
          'sub_logistic_type',
          this.shippingType
        )
      })
    },

    shippingMethod() {
      return this.$store.getters['shippingMethods/find'](
        this.orderShipment.shipping_method.id
      )
    },

    shippingType() {
      return this.shippingMethod.shipment_type_detail.shipping_type
    },

    gatewayProvider() {
      return this.shippingMethod.gateway_type
    },

    errors() {
      if (!this.shippingAddressHelper) return null

      return this.shippingAddressHelper.shipmentErrors
    },

    cvsStoreInfo() {
      return this.shipmentForm.address.address.cvs_store_info
    }
  },

  async mounted() {
    this.shippingAddressHelper = useShippingAddressHelper({
      shipmentForm: this.shipmentForm,
      firstSameDestinationShipmentForm: this.firstSameDestinationShipmentForm
    })

    this.commonAddressHelper = useCommonAddressHelper({
      addressForm: this.shipmentForm.address,
      isReferenceFromOtherForm: this.isReferenceFromOtherForm,
      addressChangedHandler: () => {
        this.shipmentForm.save_shipping_address_as_common = true
      }
    })

    await this.trySyncCvsStoreInfoFromQuery()

    this.isLoaded = true
  },

  methods: {
    trySyncCvsStoreInfoFromQuery() {
      let data = queryString.parse(window.location.search)

      if (!data.gateway) return

      let cvsDataUpdatable =
        this.gatewayProvider === data.gateway &&
        this.shippingType === data.ExtraData &&
        this.order.order_state === 'items_confirmed'

      if (cvsDataUpdatable) {
        this.syncStoreInfoToFrom(data)
        this.flagFormAsEdited()
        this.clearQueryString()
      }
    },

    flagFormAsEdited() {
      this.$emit('form-edited')
    },

    syncStoreInfoToFrom(data) {
      this.shipmentForm.address.address.cvs_store_info =
        this.convertMapInfo(data)

      this.errors.clear('cvs_store_info')
    },

    async getParamsAndGoForSelect() {
      if (!this.usingJdWindowOpenMap) await this.storeFormData()

      this.cartService
        .expressMap(this.orderShipment, {
          device: this.isMobile ? 'mobile' : null
        })
        .then(() => {
          this.openExpressMap(this.expressMapUrl, [
            this.flagFormAsEdited,
            this.clearQueryString
          ])
        })
    },

    isReferenceFromOtherForm() {
      return (
        this.shipmentForm.is_same_as_billing_address ||
        this.shipmentForm.same_address_shipment_id
      )
    }
  }
}
</script>
