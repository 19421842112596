<template lang="pug">
.field.is-full.checkbox-wrapper
  b-checkbox(
    type="is-odd"
    size="is-small"
    :value="saveAddressAsCommon"
    @input="updateHandler"
  )
    | {{ actionLocaleText('save_as_common_address') }}
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    saveAddressAsCommon: {
      type: Boolean,
      required: true
    }
  },

  setup(props, { emit }) {
    const updateHandler = (value) => {
      emit('update:save-address-as-common', value)
    }

    return {
      updateHandler
    }
  }
})
</script>
