<template lang="pug">
.vc-common-blog-mobile-category-menu
  ul.mobile-menu-wrapper
    li.menu-item(
      v-for="category in categories"
      :key="category.id"
    )
      a.tab(
        :href="`/categories/${category.id}/blogs`"
        :class="tabClasses(category)"
      )
        span
          | {{ category.name }}
</template>

<script>
import BlogCategoryBasicMixins from '../mixins/blog_category_basic_mixin.js'

export default {
  // components: {},
  mixins: [BlogCategoryBasicMixins],

  props: {
    currentCategoryId: {
      type: [String, undefined],
      required: false
    }
  },
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  methods: {
    tabClasses(category) {
      return {
        actived:
          category.id === this.currentCategoryId ||
          this.subCategories(category)
            .map((category) => category.id)
            .includes(this.currentCategoryId)
      }
    }
  }
}
</script>
