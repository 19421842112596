import KCarouselAdsModal from '@ksharedComponents/modal/k-carousel-ads-modal.vue'
import MobileQuickLinkWrapper from '../components/common/mobile-quick-link-wrapper.vue'
import HomepagePreferenceContainer from '../components/page_preference/homepage-container.vue'

export default {
  name: 'landing-page-container',

  components: {
    KCarouselAdsModal,
    MobileQuickLinkWrapper,
    HomepagePreferenceContainer
  },

  // mixins: [],

  // props: {},

  // data() {
  //   return {}
  // },

  computed: {
    displayConfig() {
      return (
        this.$store.getters['siteConfigs/siteCustomDisplayConfig'].page_index ||
        {}
      )
    }
  },

  // created() {},

  async mounted() {
    await this.tryRestoreScrollPosition()
  }

  // methods: {}
}
