<template lang="pug">
.vc-product-category-k-select-goods
  .cover(:style="`background-image: url('${coverSrc}')`")
    .overlay
    .category-name {{ category.name }}

  .products-wrapper(
    :style="`grid-template-columns: repeat(${selectGoods.length <= 4 ? 4 : selectGoods.length}, minmax(25%, 1fr))`"
  )
    product-card(
      v-for="(product, index) in selectGoods"
      :key="product.id"
      :product="product"
      :index="index"
      :list-location="listLocation"
      @ga-impressed="impressedHandler"
      id-prefix="select"
    )
</template>

<script>
import ProductCard from '../product/card.vue'
import productImpressionMixin from '../mixins/product_impression_mixin.js'
import generateGA4Events from '@services/generate_ga4_events'

const MOBILE_IMAGE_BREAK_POINT = 415

export default {
  components: {
    ProductCard
  },

  mixins: [productImpressionMixin],

  props: {
    category: {
      type: Object,
      required: true
    },

    productCount: {
      type: Number,
      required: false,
      default: () => {
        return 8
      }
    }
  },

  data() {
    return {
      productIds: null
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['productCategories/isLoading']
    },

    isMobile() {
      // workaround for safari can't get window width from vuex at first time loading
      return (
        (this.$store.getters['windowWidth'] || window.innerWidth) <
        MOBILE_IMAGE_BREAK_POINT
      )
    },

    hasCategory() {
      return typeof this.category.id === 'string'
    },

    coverSrc() {
      return this.isMobile
        ? this.category.mobile_cover?.url || this.category.cover?.url
        : this.category.cover?.url
    },

    selectGoods() {
      if (!this.productIds) return []
      return this.$store.getters['products/all'].filter((product) =>
        this.productIds.includes(product.id)
      )
    },

    listLocation() {
      return `Select Goods - ${this.category?.name}`
    }
  },

  // created() {},

  async mounted() {
    await this.fetchCategorySelectGoods()
  },

  methods: {
    fetchCategorySelectGoods() {
      if (!this.hasCategory) return

      return this.$store
        .dispatch('productCategories/selectGoods', {
          model: this.category,
          options: {
            pageSize: this.productCount,
            pageNumber: 1
          }
        })
        .then((response) => {
          this.productIds = response.data.data.map((product) => product.id)
        })
        .then(() => {
          this.$store.dispatch('ga4Operation', [
            generateGA4Events('view_item_list', {
              items: this.selectGoods,
              item_list_name: this.listLocation
            })
          ])
        })
    }
  }
}
</script>
