<template lang="pug">
nav.vc-scope-tabs
  .tabs
    .tab-item(:class="{ 'is-active': userScope == 'user' }")
      a(@click="selectScope('user')")
        span {{ actionLocaleText('user_login') }}

    .tab-item(:class="{ 'is-active': userScope == 'promoter_member' }")
      a(@click="selectScope('promoter_member')")
        span {{ actionLocaleText('promoter_login') }}

    .tab-item(:class="{ 'is-active': userScope == 'partner' }")
      a(@click="selectScope('partner')")
        span {{ actionLocaleText('partner_login') }}
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    userScope: {
      type: String,
      required: true,
      validator(value) {
        return ['user', 'partner', 'promoter_member'].includes(value)
      }
    }
  },

  setup(props, { emit }) {
    const selectScope = (value) => {
      emit('update:userScope', value)
    }

    return {
      selectScope
    }
  }
})
</script>
