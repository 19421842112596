const TAPPAY_SDK_URL = 'https://js.tappaysdk.com/sdk/tpdirect/v5.14.0'

export default {
  // components: {},
  // props: {},
  // data() {
  //   return {}
  // },

  computed: {
    configs() {
      return this.$store.getters['siteConfigs/allConfigs']
    },

    tappayConfig() {
      return this.configs.app_payment_gateways.find(
        (app_payment_gateway) => app_payment_gateway.provider === 'tappay'
      )
    },

    environment() {
      return this.configs.environment
    },

    serverType() {
      return 'production'
      return this.configs.environment === 'production'
        ? 'production'
        : 'sandbox'
    }
  },

  methods: {
    setupTappay() {
      return new Promise((resolve, reject) => {
        this._initTappay()
          .then((_) => this._fetchAppPaymentGateways())
          .then((_) => {
            console.log(
              'check',
              this.tappayConfig.app_id,
              this.tappayConfig.app_key,
              this.serverType
            )

            TPDirect.setupSDK(
              this.tappayConfig.app_id,
              this.tappayConfig.app_key,
              this.serverType
            )

            resolve()
          })
      })
    },

    _initTappay() {
      return new Promise((resolve, reject) => {
        if (window.TPDirect) {
          resolve()
        } else {
          this._getScript(TAPPAY_SDK_URL, (_) => {
            resolve()
          })
        }
      })
    },

    _getScript(source, callback) {
      /* 取得script */
      var script = document.createElement('script')
      var prior = document.getElementsByTagName('script')[0]
      script.async = 1

      script.onload = script.onreadystatechange = function (_, isAbort) {
        if (
          isAbort ||
          !script.readyState ||
          /loaded|complete/.test(script.readyState)
        ) {
          script.onload = script.onreadystatechange = null
          script = undefined

          if (!isAbort && callback) setTimeout(callback, 0)
        }
      }

      script.src = source
      prior.parentNode.insertBefore(script, prior)
    },

    _fetchAppPaymentGateways() {
      return this.$store.dispatch('siteConfigs/appPaymentGateways')
    }
  }
}
