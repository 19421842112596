<template lang="pug">
.vc-order-shipping-address-form
  .address-form-unit(
    v-for="shipmentForm in shipmentForms"
    :key="shipmentForm.id"
  )
    //- normal
    shipping-address-home-form(
      v-if="shipmentForm.shipping_destination === 'home'"
      :shipment-form.sync="shipmentForm"
      :first-same-destination-shipment-form="findFirstSameDestinationShipmentFormFor(shipmentForm)"
    )

    //- CVS Selector
    template(v-for="cvsDestination in cvsDestinationList")
      shipping-address-cvs-selector(
        v-if="shipmentForm.shipping_destination === cvsDestination"
        :shipment-form.sync="shipmentForm"
        :first-same-destination-shipment-form="findFirstSameDestinationShipmentFormFor(shipmentForm)"
        :cart-service="cartService"
        :store-form-data="storeFormData"
      )

    //- oversea
    shipping-address-oversea-form.mb-4(
      v-if="shipmentForm.shipping_destination === 'oversea'"
      :shipment-form.sync="shipmentForm"
      :first-same-destination-shipment-form="findFirstSameDestinationShipmentFormFor(shipmentForm)"
    )

    //- Store Selector
    shipping-address-store-selector.mb-4(
      v-if="shipmentForm.shipping_destination === 'store'"
      :shipment-form.sync="shipmentForm"
      :first-same-destination-shipment-form="findFirstSameDestinationShipmentFormFor(shipmentForm)"
    )
</template>

<script>
import { defineComponent, computed, reactive, onMounted, watch } from 'vue'
import { useStore } from 'skid-composables'
import union from 'lodash.union'
import ShippingAddressHomeForm from './shipping-address-home-form.vue'
import ShippingAddressCvsSelector from './shipping-address-cvs-selector.vue'
import ShippingAddressStoreSelector from './shipping-address-store-selector.vue'
import ShippingAddressOverseaForm from './shipping-address-oversea-form.vue'

export default defineComponent({
  components: {
    ShippingAddressHomeForm,
    ShippingAddressCvsSelector,
    ShippingAddressStoreSelector,
    ShippingAddressOverseaForm
  },

  props: {
    shipmentForms: {
      type: Array,
      required: true
    },

    cartService: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      required: true
    },

    storeFormData: {
      type: Function,
      required: false
    }
  },

  setup(props, { emit }) {
    const store = useStore()

    const cvsDestinationList = computed(() => {
      return [
        'cvs_fami',
        'cvs_unimart',
        'cvs_unimart_freeze',
        'cvs_hilife',
        'cvs_okmart'
      ]
    })

    const findFirstSameDestinationShipmentFormFor = (shipmentForm) => {
      return props.shipmentForms.find((form) => {
        return form.shipping_destination === shipmentForm.shipping_destination
      })
    }

    const formEditedHandler = () => {
      emit('form-edited')
    }

    return {
      formEditedHandler,
      cvsDestinationList,
      findFirstSameDestinationShipmentFormFor
    }
  }
})
</script>
