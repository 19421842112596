<template lang="pug">
.vc-order-shipping-address-oversea-form.vc-order-address-form
  .section-label
    //- span {{ copyLocaleText('oversea_information') }}
    span {{ shippingAddressHelper.partner.value.name }}
    span {{ shippingAddressHelper.shippingMethod.value.name }}

  .section-label
    span
      b-checkbox(
        v-if="shipmentForm.id != firstSameDestinationShipmentForm.id"
        type="is-odd"
        :true-value="firstSameDestinationShipmentForm.id"
        :fale-value="null"
        v-model="shipmentForm.same_address_shipment_id"
      ) {{ `與${shippingAddressHelper.firstDestinationPartner.value.name} ${shippingAddressHelper.firstDestinationShippingMethod.value.name} 相同` }}

    common-address-button(
      v-if="commonAddressHelper.isCommonAddresButtonShow.value"
      :address="shipmentForm.address"
      :shipping-type="shippingAddressHelper.shippingType.value"
      @selected-address="commonAddressHelper.selectCommonAddressHandler"
    )

  .form-section.has-sub-fields
    b-field(
      :label="attributeLocaleText('user/common_address', 'recipient')"
      :type="errors.errorClassAt('addr_recipient')"
      :message="errors.get('addr_recipient')"
    )
      b-input(
        type="text"
        name="name"
        autocomplete="name"
        v-model="shipmentForm.address.recipient"
        @input="errors.clear('addr_recipient')"
      )
    b-field(
      :label="attributeLocaleText('user/common_address', 'phone')"
      :type="errors.errorClassAt('addr_phone')"
      :message="errors.get('addr_phone')"
    )
      p.control.calling-code
        b-dropdown(
          v-model="shipmentForm.address.dial_code"
          :max-height="200"
          scrollable
          @input="errors.clear('addr_phone')"
        )
          b-button.button(
            slot="trigger"
            icon-right="caret-down"
            outlined
          )
            span {{ shipmentForm.address.dial_code }}
          b-dropdown-item(custom)
            b-input(
              v-model="searchCallingCodes"
              placeholder="search"
              expanded
            )
          b-dropdown-item(
            v-for="(code, index) in countryCallingCodes"
            :key="index"
            :value="code.dial_code"
            aria-role="listitem"
          ) {{ `${code.emoji} ${code.name} ${code.dial_code}` }}
      b-input(
        type="tel"
        autocomplete="tel"
        v-model="shipmentForm.address.phone"
        @input="errors.clear('addr_phone')"
      )

    b-field(
      :label="attributeLocaleText('order/address', 'zip_code')"
      :type="errors.errorClassAt('addr_zip')"
      :message="errors.get('addr_zip')"
    )
      b-input(
        v-model="shipmentForm.address.address.zip"
        autocomplete="zip"
        @input="errors.clear('addr_zip')"
      )

    b-field.is-full(
      :label="attributeLocaleText('user/common_address', 'address')"
      :type="errors.errorClassAt('addr_street_address')"
      :message="errors.get('addr_street_address')"
    )
      b-input(
        type="text"
        v-model="shipmentForm.address.address.street_address"
        autocomplete="street-address"
        @input="errors.clear('addr_street_address')"
      )

    save-address-as-common-field(
      v-if="commonAddressHelper.isSaveAddressAsCommonShow.value"
      :save-address-as-common.sync="shipmentForm.save_shipping_address_as_common"
    )
</template>

<script>
import { defineComponent, computed, onMounted } from 'vue'
import { useStore } from 'skid-composables'
import CommonAddressButton from './common-address-button.vue'
import SaveAddressAsCommonField from './save-address-as-common-field.vue'
import CitySelect from '@sharedComponents/city-select.vue'
import AreaSelect from '@sharedComponents/area-select.vue'
import useCallingCodesDropdown from '../../../../../shared/composables/use_calling_codes_dropdown.js'
import useShippingAddressHelper from './shipping-address-helper'
import useCommonAddressHelper from './common-address-helper.js'

export default defineComponent({
  components: {
    CommonAddressButton,
    SaveAddressAsCommonField,
    CitySelect,
    AreaSelect
  },

  props: {
    shipmentForm: {
      type: Object,
      required: true
    },

    firstSameDestinationShipmentForm: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit }) {
    const { searchCallingCodes, countryCallingCodes } =
      useCallingCodesDropdown()

    const shippingAddressHelper = useShippingAddressHelper({
      shipmentForm: props.shipmentForm,
      firstSameDestinationShipmentForm: props.firstSameDestinationShipmentForm
    })

    const commonAddressHelper = useCommonAddressHelper({
      addressForm: props.shipmentForm.address,
      isReferenceFromOtherForm: () => {
        return (
          props.shipmentForm.is_same_as_billing_address ||
          props.shipmentForm.same_address_shipment_id
        )
      },
      addressChangedHandler: () => {
        props.shipmentForm.save_shipping_address_as_common = true
      }
    })

    onMounted(() => {})

    const errors = computed(() => {
      return shippingAddressHelper.shipmentErrors.value
    })

    return {
      searchCallingCodes,
      countryCallingCodes,
      shippingAddressHelper,
      commonAddressHelper,
      errors
    }
  }
})
</script>
