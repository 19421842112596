import CategoryMenu from '../../components/content_category/category-menu.vue'
import ContentSection from '../../components/content/section.vue'
import SlideshowContainer from '../../components/common/slideshow-container.vue'
import OddPagination from '../../components/common/odd-pagination.vue'

export default {
  name: 'contents-index-page-container',

  components: {
    CategoryMenu,
    ContentSection,
    SlideshowContainer,
    OddPagination
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      defaultUsages: null
    }
  },
  computed: {
    isLoading() {
      return this.$store.getters['categorySystems/isLoading']
    },

    usages() {
      return this.$store.getters['categorySystems/allCategories']({
        depth: 1,
        defaultUsages: this.defaultUsages
      })
    },

    contents() {
      return this.$store.getters['contents/all']
    },

    hasContent() {
      return this.contents.length > 0
    }
  },

  // created() {},

  mounted() {
    this.defaultUsages = this.$el.dataset.defaultUsages.split(',')
  }

  // methods: {}
}
