import CategoryMenu from '../../components/content_category/category-menu.vue'
import EditorContentWrapper from '../../components/content/editor-content-wrapper.vue'
import LatestContents from '../../components/content/latest-contents.vue'
import RelevantContents from '../../components/content/relevant-contents.vue'

export default {
  name: 'content-show-page-container',

  components: {
    CategoryMenu,
    EditorContentWrapper,
    LatestContents,
    RelevantContents
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      contentId: null,
      defaultUsages: null
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['contents/isLoading']
    },

    content() {
      return this.$store.getters['contents/find'](this.contentId)
    },

    hasContent() {
      return typeof this.content.id === 'string'
    }
  },

  // created() {},

  mounted() {
    this.contentId = this.$el.dataset.contentId
    this.defaultUsages = this.$el.dataset.defaultUsages.split(',')
    this.$store.dispatch('contents/find', this.contentId).then((_) => {
      this.selectedCategoryId = this.content.category.id
    })
  }

  // methods: {}
}
