<template lang="pug">
.vc-order-shipping-and-payment-method.odd-container.has-padding(
  v-if="isFormLoaded"
)
  b-loading(:active.sync="isLoading")

  .form-container
    .form-column
      //- email
      .section-label
        span {{ copyLocaleText('contact_infomation') }}
      .form-section
        b-field(
          :label="attributeLocaleText('order', 'email')"
          :type="errors.errorClassAt('email')"
          name="email"
          :message="errors.get('email')"
        )
          b-input(
            type="email"
            v-model="form.email"
            required
            autocomplete="email"
            :loading="isEmailChecking"
            @input="errors.clear('email')"
            @keyup.native="formEditedHandler"
            @blur="checkForExistedMember"
          )

      //- billing address
      billing-address-form(
        :billing-address.sync="form.billing_address"
        :save-billing-address-as-commoon.sync="form.save_billing_address_as_common"
        @form-edited="formEditedHandler"
        @update:recipient="syncToShipping('recipient', $event)"
        @update:phone="syncToShipping('phone', $event)"
      )

      shipping-addresses-form(
        :shipment-forms.sync="form.shipments"
        :cart-service="cartService"
        :errors="order.errors"
        :store-form-data="storeFormDataFromLocalStorage"
      )

    .form-column.-clean
      //- payment method selector
      .section-label(:class="errors.errorClassAt('payment_method_id')")
        span {{ modelNameLocaleText('payment_method') }}
      .form-section(:class="errors.errorClassAt('payment_method_id')")
        payment-method-selector(
          :selected-payment-method-id.sync="form.payment_method_id"
          :selected-user-credit-card-id.sync="form.user_credit_card_id"
          :paymentMethods="paymentMethods"
          @form-edited="formEditedHandler"
        )

      //- notes
      .section-label
        span {{ attributeLocaleText('order', 'notes') }}
      .form-section
        b-field(
          :type="errors.errorClassAt('notes')"
          :message="errors.get('notes')"
        )
          b-input(
            type="textarea"
            v-model="form.notes"
            @input="formEditedHandler(); errors.clear('notes')"
          )

      //- aggrements
      .section-label
        span {{ copyLocaleText('user_aggrement_and_notes') }}
      .form-section.checkboxes
        .field
          b-checkbox(
            v-model="form.is_agree_with_term_of_service"
            size="is-small"
            type="is-odd"
          )
            span(
              v-html="copyLocaleText('i_agree_with_terms_of_service_and_privacy_policy')"
            )
        .field(v-if="currentUser.isGuest()")
          b-checkbox(
            v-model="form.is_agree_to_join_membership"
            size="is-small"
            type="is-odd"
          ) {{ copyLocaleText('join_membership_after_checkout', { site_name: copyLocaleText('site_basics.site_name') }) }}
        .field
          b-checkbox(
            v-model="form.is_agree_to_receive_news_letters"
            size="is-small"
            type="is-odd"
          ) {{ copyLocaleText('i_would_like_to_receive_newsletters') }}

      //- price detail
      .section-label
        span {{ attributeLocaleText('order', 'price_detail') }}
      .form-section
        price-detail(:order="order")

  .cart-options
    back-to-store-button
    .button.is-odd.back-to-store.next-step(
      @click="placeOrder"
      :disabled="!form.is_agree_with_term_of_service"
      :class="{ 'is-loading': isLoading }"
    ) {{ actionLocaleText('next_step') }}
</template>

<script>
import checkoutFlowMixin from '../mixins/checkout_flow_mixin.js'
import PlaceOrderForm from '../../../../shared/forms/place_order_form.js'
import PaymentMethodSelector from './payment-method-selector.vue'
import BackToStoreButton from './back-to-store-button.vue'
import ActionConfirmService from '../../../../shared/services/action_confirm_service.js'
import BillingAddressForm from './shipping_and_payment_method/billing-address-form.vue'
import ShippingAddressesForm from './shipping_and_payment_method/shipping-addresses-form.vue'
import generateGA4Events from '@services/generate_ga4_events'

const STORE_FORM_DATA_KEY = 'place-order-form'

export default {
  components: {
    PaymentMethodSelector,
    BackToStoreButton,
    ShippingAddressesForm,
    BillingAddressForm
  },

  mixins: [checkoutFlowMixin],

  props: {
    defaultJoinMembershipAfterCheckout: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    }
  },

  data() {
    return {
      isFormLoaded: false,
      isFormEdited: false,
      form: null,
      isEmailChecking: false,
      isEmailChanged: false
    }
  },

  computed: {
    shipments() {
      return this.order.shipments
        .map((shipment) => {
          return this.$store.getters['orderShipments/find'](shipment.id)
        })
        .sort((a, b) => {
          return parseInt(a.id) - parseInt(b.id)
        })
    }
  },

  watch: {
    'form.payment_method_id': {
      handler() {
        this.errors.clear('payment_method_id')
      }
    },

    'form.email': {
      handler(newEmail) {
        this.isEmailChanged = true
      }
    },

    form: {
      deep: true,
      handler() {
        if (!this.isFormLoaded) return

        this.isFormEdited = true
      }
    }
  },
  // created() {},
  async mounted() {
    this.form = new PlaceOrderForm(
      Object.assign(this.order, { shipments: this.shipments })
    )

    if (this.currentUser.isGuest())
      this.isAgreeToJoinMembershipAfterCheckout = true
    if (this.order.payment.id)
      this.form.payment_method_id = this.paymentMethodFromOrderPayment.id
    this.form.email = this.order.email
    this.form.is_agree_to_join_membership =
      this.defaultJoinMembershipAfterCheckout
    this._tryFetchPaymentMethods()
    this._trackCheckoutProgress()

    await this.tryToRestoreFormDataFromLocalStorage()

    this.isFormLoaded = true
  },

  methods: {
    checkForExistedMember() {
      if (this.isUserSignedIn || !this.isEmailChanged) return

      this.isEmailChecking = true
      this.$store
        .dispatch('users/check', this.form.email)
        .then((result) => {
          if (result.data.data.result) this._showLoginRequestAlert()
        })
        .finally((_) => {
          this.isEmailChecking = false
          this.isEmailChanged = false
        })
    },

    _showLoginRequestAlert() {
      new ActionConfirmService({
        type: 'question',
        title: this.messageLocaleText(
          'confirmations.would_you_like_to_login_as_a_member'
        ),
        text: this.messageLocaleText(
          'help.if_place_order_as_a_guest_you_will_need_to_check_email_for_tracking_your_order_status'
        ),
        confirmButtonText: this.actionLocaleText('shopping_as_a_member'),
        cancelButtonText: this.actionLocaleText('shopping_as_a_guest'),
        reverseButtons: true
      }).confirm(this._goToLoginPage)
    },

    _goToLoginPage() {
      Turbolinks.visit(`/user/login?email=${this.form.email}`)
    },

    formEditedHandler() {
      this.isFormEdited = true
    },

    placeOrder() {
      if (!this.form.is_agree_with_term_of_service) return

      if (this.order.order_state === 'items_confirmed' || this.isFormEdited) {
        this.cartService
          .placeOrder(this.form.sync())
          .then(() => {
            this.$store.dispatch('ga4Operation', [
              generateGA4Events('add_shipping_info', {
                items: this.cartItems,
                variants: this.$store.getters['productVariants/all'],
                value: this.toMoney(this.order.total).amount
              })
            ])
          })
          .catch((errors) => {
            if (errors.response.status === 500)
              this.$emit('update:currentStep', 1)
          })
      } else {
        this.$emit('update:currentStep', 3)
      }

      this._scrollToTop()
    },

    storeFormDataFromLocalStorage() {
      return this.$vlf.setItem(STORE_FORM_DATA_KEY, {
        id: this.form.model.id,
        data: this.form.data()
      })
    },

    async tryToRestoreFormDataFromLocalStorage() {
      const localFormData = await this.$vlf.getItem(STORE_FORM_DATA_KEY)

      if (!localFormData || localFormData.id != this.form.model.id) return

      this.form.constructor.dataDumper(
        localFormData.data,
        this.form,
        this.form.originalData
      )

      return this.$vlf.setItem(STORE_FORM_DATA_KEY, null)
    },

    syncToShipping(location, data) {
      this.form.shipments.forEach((shipment) => {
        shipment.address[location] = data
      })
    }
  }
}
</script>
