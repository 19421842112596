<template lang="pug">
.vc-promoter-event-product-list
  .camapign-and-category-tabs
    b-tabs(
      v-model="currentTab"
      v-if="tabs.length > 0"
      @input="tabChangeHandler"
    )
      b-tab-item(
        v-for="(tab, index) in tabs"
        :label="tab.label"
        :key="index"
      )

  .products-wrapper
    product-unit(
      v-for="(product, index) in products"
      :key="product.id"
      :product="product"
      :index="index"
      :promoter-event="promoterEvent"
      :promoter-share="promoterShare"
      :list-location="listLocation"
      @ga-impressed="impressedHandler"
    )

    .loading-wrapper
      b-loading(
        :active.sync="isProductsLoading"
        :is-full-page="false"
      )

  //- odd-pagination(
  //-   :total="totalCount"
  //-   :current.sync="currentPage"
  //-   :per-page="pageSize"
  //-   size="is-small"
  //-   order="is-centered"
  //-   :rounded="true"
  //-   @change="onPageChange"
  //- )
</template>

<script>
import productImpressionMixin from '../mixins/product_impression_mixin.js'
import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import ProductUnit from './product-unit.vue'
import OddPagination from '../common/odd-pagination.vue'
import PromoterCampaign from '@models/promoter_campaign'
import useImpressionService from '../../composables/use_impression_service'
import generateGA4Events from '@services/generate_ga4_events'

export default {
  components: {
    ProductUnit,
    OddPagination
  },

  mixins: [productImpressionMixin, backendPaginateFilterSortAndSearchableMixin],

  props: {
    promoterEvent: {
      type: Object,
      required: true
    },

    promoterShare: {
      type: Object,
      requrired: true
    }
  },

  data() {
    return {
      resourceType: 'promoterShareProductShips',
      currentTab: 0,
      currentPage: 1,
      pageSize: 12,
      // sortOrder: 'asc',
      // sortField: 'promoter_event_product_ships.position',
      isProductsLoading: false,
      campaignIds: [],
      productCategoryIds: [],
      promoterShareProductShipIds: [],
      newPromoterShareProductShipIds: [],
      impressionCampaignCategories: []
    }
  },

  computed: {
    products() {
      return this.promoterEventProductShips
        .map((promoterEventProductShip) => {
          return this.$store.getters['products/find'](
            promoterEventProductShip.product_id
          )
        })
        .filter((product) => {
          return product.isDataLoaded() // product is later loaded than promoterEventProductShip
        })
    },

    listLocation() {
      return `Promoter Event - ${this.promoterEvent.name}`
    },

    listContainerOffsetTop() {
      return this.$el.offsetParent.offsetTop - 50 - 90
    },

    shareDescription() {
      return this.promoterShare.description
    },

    promoterShareProductShips() {
      return this.promoterShareProductShipIds.map((id) => {
        return this.$store.getters['promoterShareProductShips/find'](id)
      })
    },

    promoterEventProductShips() {
      return this.promoterShareProductShips.map((ship) => {
        return this.$store.getters['promoterEventProductShips/find'](
          ship.event_product_ship_id
        )
      })
    },

    newPromoterShareProductShips() {
      return this.newPromoterShareProductShipIds.map((id) => {
        return this.$store.getters['promoterShareProductShips/find'](id)
      })
    },

    newPromoterEventProductShips() {
      return this.newPromoterShareProductShips.map((ship) => {
        return this.$store.getters['promoterEventProductShips/find'](
          ship.event_product_ship_id
        )
      })
    },

    promoterCampaigns() {
      return this.campaignIds.map((id) => {
        return this.$store.getters['promoterCampaigns/find'](id)
      })
    },

    productCategories() {
      return this.productCategoryIds.map((id) => {
        return this.$store.getters['productCategories/find'](id)
      })
    },

    tabs() {
      let tabs = []

      const mainCampaigns = this.promoterCampaigns.filter((campaign) => {
        return campaign.category === 'main'
      })

      if (mainCampaigns.length > 0)
        tabs.push({
          label: '主打檔期',
          campaignCategory: 'main'
        })

      const kolSelectCampaigns = this.promoterCampaigns.filter((campaign) => {
        return campaign.category === 'kol-select'
      })

      if (kolSelectCampaigns.length > 0)
        tabs.push({
          label: 'KOKO select',
          campaignCategory: 'kol-select'
        })

      return tabs.concat(
        this.productCategories.map((category) => {
          return {
            label: category.name,
            campaignCategory: 'official',
            model: category
          }
        })
      )
    },

    selTab() {
      return this.tabs[this.currentTab]
    },

    totalPages() {
      return this.$store.getters[`${this.resourceType}/meta`].total_pages
    }
  },
  // created() {},
  async mounted() {
    await this.fetchStoreFrontCampaigns()

    await this.fetchStoreFrontProductCategories()

    await this.fetchingInitialData()

    await this.listenScrollDown()
  },

  methods: {
    async fetchData(options) {
      this.currentPage = options.pageNumber
      options.filter = 'store_front'
      this.isProductsLoading = true

      try {
        await this.fetchShareProductShips(options)

        await this.fetchEventProductShips()

        await this.fetchProducts()

        await this.tryCreateImpressions()

        await this.$store.dispatch('ga4Operation', [
          generateGA4Events('view_item_list', {
            items: this.products,
            list_name: this.listLocation
          })
        ])
      } catch (error) {
        this.isProductsLoading = false
      }

      this.isProductsLoading = false
    },

    async fetchStoreFrontCampaigns() {
      const response = await this.$store.dispatch(
        'promoterShares/fetchStoreFrontCampaigns',
        {
          model: this.promoterShare
        }
      )

      this.campaignIds = response.data.data.map((row) => row.id)
    },

    async fetchStoreFrontProductCategories() {
      const response = await this.$store.dispatch(
        'promoterShares/fetchStoreFrontProductCategories',
        {
          model: this.promoterShare,
          options: {
            sort: 'position'
          }
        }
      )

      this.productCategoryIds = response.data.data.map((row) => row.id)
    },

    async fetchShareProductShips(options) {
      if (this.tabs.length === 0) return

      switch (this.selTab.campaignCategory) {
        case 'main':
          options.search.event_product_ship_campaign_category_eq =
            PromoterCampaign.categories['main']
          options.search.event_product_ship_product_categories_id_eq = null
          break
        case 'kol-select':
          options.search.event_product_ship_campaign_category_eq =
            PromoterCampaign.categories['kol-select']
          options.search.event_product_ship_product_categories_id_eq = null
          break
        case 'official':
          options.search.event_product_ship_campaign_category_eq =
            PromoterCampaign.categories['official']
          options.search.event_product_ship_product_categories_id_eq =
            this.selTab.model.id
          break
      }

      const response = await this.$store.dispatch(
        `promoterShares/fetchShareProductShipsV2`,
        {
          model: this.promoterShare,
          options: Object.assign(options, {
            replace: true
          })
        }
      )

      this.newPromoterShareProductShipIds = response.data.data.map(
        (ship) => ship.id
      )

      if (this.currentPage === 1) {
        this.promoterShareProductShipIds = this.newPromoterShareProductShipIds
      } else {
        this.promoterShareProductShipIds =
          this.promoterShareProductShipIds.concat(
            this.newPromoterShareProductShipIds
          )
      }
    },

    async fetchEventProductShips() {
      if (this.newPromoterShareProductShips.length === 0) return

      await this.$store.dispatch(`promoterShares/fetchEventProductShips`, {
        model: this.promoterShare,
        options: {
          search: {
            id_in: this.newPromoterShareProductShips.map(
              (ship) => ship.event_product_ship_id
            )
          }
        }
      })
    },

    async fetchProducts() {
      if (this.newPromoterEventProductShips.length === 0) return

      await this.$store.dispatch(`promoterShares/fetchProducts`, {
        model: this.promoterShare,
        options: {
          search: {
            id_in: this.newPromoterEventProductShips.map(
              (ship) => ship.product_id
            )
          }
        }
      })
    },

    tabChangeHandler() {
      this.currentPage = 1
      this.promoterShareProductShipIds = []

      this.fetchData(this.currentOptions)
    },

    listenScrollDown() {
      window.document.addEventListener('scroll', (event) => {
        this.detectToScrollBottom(() => {
          if (this.currentPage >= this.totalPages) return
          if (this.isProductsLoading) return

          this.currentPage += 1

          this.fetchData(this.currentOptions)
        })
      })
    },

    detectToScrollBottom(goButtonHandler) {
      const windowHeight = window.innerHeight
      const scrollY = window.scrollY
      const bodyHeight = document.body.scrollHeight
      const buffer = 30

      if (windowHeight + scrollY + buffer >= bodyHeight) {
        goButtonHandler()
      }
    },

    async tryCreateImpressions() {
      const campaignCategory = this.selTab.campaignCategory

      if (campaignCategory === 'official') return
      if (this.impressionCampaignCategories.includes(campaignCategory)) return

      const campaigns = this.promoterCampaigns.filter((campaign) => {
        return campaign.category === campaignCategory
      })

      const response = await this.$store.dispatch(
        'promoterShares/fetchCampaignShareShips',
        {
          model: this.promoterShare,
          options: {
            search: {
              campaign_id_in: campaigns.map((campaign) => campaign.id)
            }
          }
        }
      )

      const campaignShareShipIds = response.data.data.map((record) => {
        return record.id
      })

      for (let i = 0; i < campaignShareShipIds.length; i++) {
        const id = campaignShareShipIds[i]

        const service = await useImpressionService({
          impressionableType: 'Promoter::CampaignShareShip',
          impressionableId: id,
          store: this.$store
        })

        service.createImpression()
      }

      this.impressionCampaignCategories.push(campaignCategory)
    }
  }
}
</script>
