<template lang="pug">
.vc-content-card
  .inner
    a.content-image(:href="contentLink")
      img.image(v-lazy="coverSrc")
    .content-info
      .content-time
        .time
          | {{ dateTimeLocaleText(content.published_at, (format = 'LL')) }}
      .content-title
        a.inner-wrapper(:href="contentLink")
          h4.title
            | {{ content.title }}
      .content-description
        .description
          | {{ content.description() }}
      .options-wrapper
        .option-button
          a.button.is-default(:href="contentLink")
            span.text {{ copyLocaleText('read_content') }}
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'skid-composables'

const MOBILE_MENU_BREAK_POINT = 429

// props
const props = defineProps<{
  content?: Object
}>()

const store = useStore()

// computed
/**
 * @returns {computedRef<boolean>}
 */
const isMobile = computed<Boolean>(() => {
  return (
    (store.getters['windowWidth'] || window.innerWidth) <
    MOBILE_MENU_BREAK_POINT
  )
})
/**
 * @returns {computedRef<string>}
 */
const contentLink = computed<String>(() => {
  return `/contents/${props.content.slug || props.content.id}`
})
/**
 * @returns {computedRef<string>}
 */
const coverSrc = computed<String>(() => {
  return isMobile.value
    ? props.content.mobile_cover.url || props.content.cover.url
    : props.content.cover.url
})
</script>
