<template lang="pug">
a.form-function.vc-common-address-button(@click="openCommonAddressList")
  .icon.is-small
    i.fa.fa-list-alt
  span.text {{ actionLocaleText('choose_form_common_addresses') }}
</template>
<script>
import { defineComponent } from 'vue'
import { useModal, useInstance } from 'skid-composables'
import CommonAddressList from '../../common_address/list.vue'

export default defineComponent({
  props: {
    address: {
      type: Object,
      required: true
    },

    shippingType: {
      type: String,
      required: true
    }
  },

  setup(props, { emit }) {
    const vueInstance = useInstance()

    const openCommonAddressList = () => {
      useModal({
        parent: vueInstance,
        component: CommonAddressList,
        props: {
          selectedAddress: props.address,
          logisticType: props.shippingType
        },
        events: {
          'update:selectedAddress': (commonAddress) => {
            emit('selected-address', commonAddress)
          }
        }
      })
    }
    return {
      openCommonAddressList
    }
  }
})
</script>
