<template lang="pug">
.vc-page-preference-select-categories-container(v-if="isShowContainer")
  .odd-container
    k-section-header(:header-info="headerInfo")
  .section-body
    .odd-container
      k-select-goods(
        v-for="category in categories"
        :category="category"
        :key="category.id"
      )
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useStore } from 'skid-composables'
import KSectionHeader from '@applicationComponents/common/k-section-header.vue'
import KSelectGoods from '@applicationComponents/product_category/k-select-goods.vue'

interface Data {
  title: String
  title_en: String
  subtitle: String
  category_ids: Array<String>
}

// props
const props = defineProps<{
  data: Data
}>()

const store = useStore()

// computed
/**
 * @returns {computedRef<Object>}
 */
const headerInfo = computed<Object>(() => {
  return {
    title: props.data.title,
    titleEn: props.data.title_en,
    subtitle: props.data.subtitle
  }
})
/**
 * @returns {computedRef<Array<String>>}
 */
const categoryIds = computed<Array<String>>(() => {
  return props.data.category_ids
})
/**
 * @returns {computedRef<Array>}
 */
const categories = computed<Array<Object>>(() => {
  return categoryIds.value.map((id) =>
    store.getters['productCategories/find'](id)
  )
})
/**
 * @returns {computedRef<Object>}
 */
const displayConfig = computed<Object>(() => {
  return store.getters['siteConfigs/siteCustomDisplayConfig'].page_index || {}
})
/**
 * @returns {computedRef<Boolean>}
 */
const isShowContainer = computed<Boolean>(() => {
  return (
    categories.value.length > 0 && displayConfig.value.is_select_product_show
  )
})

// mounted
onMounted(async () => {
  await fetchCategories()
})

// methods
/**
 * @returns {promoise<void>}
 */
const fetchCategories = () => {
  if (categoryIds.value.length === 0) return

  store.dispatch('productCategories/all', {
    sort: 'position',
    forSearch: true,
    search: {
      id_in: categoryIds
    }
  })
}
</script>
