import CategoryMenu from '../../components/content_category/category-menu.vue'
import ContentCard from '../../components/content/card.vue'
import HotContents from '../../components/content/hot-contents.vue'
import OddPagination from '../../components/common/odd-pagination.vue'
import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'

export default {
  name: 'content-category-index-page-container',

  components: {
    CategoryMenu,
    ContentCard,
    HotContents,
    OddPagination
  },

  mixins: [backendPaginateFilterSortAndSearchableMixin],

  // props: {},

  data() {
    return {
      resourceType: 'contents',
      currentPage: 1,
      pageSize: 10,
      sortField: '-published_at',
      selectedCategoryId: undefined,
      defaultUsages: null
    }
  },

  computed: {
    sectionTitle() {
      if (this.isCategorySelected) return this.selectedCategory.name
      if (Object.keys(this.searchOptions).length > 0)
        return this.copyLocaleText('search_result')
    },

    isLoading() {
      return this.$store.getters['categorySystems/isLoading']
    },

    isCategorySelected() {
      return this.selectedCategory.id !== undefined
    },

    selectedCategory() {
      return this.$store.getters['categorySystems/findCategory'](
        this.selectedCategoryId
      )
    },

    contents() {
      return this.$store.getters['contents/all']
    },

    firstContent() {
      return this.contents[0]
    },

    sideContents() {
      return this.contents.slice(1, 4)
    },

    bottomContents() {
      return this.contents.slice(4, 10)
    }
  },

  watch: {
    selectedCategory: {
      handler(newValue) {
        if (newValue && !!this.selectedCategory) {
          this.fetchingInitialData()
        }
      }
    }
  },

  // created() {},

  mounted() {
    this.selectedCategoryId = this.$el.dataset.category
    this.defaultUsages = this.$el.dataset.defaultUsages.split(',')
  },

  methods: {
    fetchData(options) {
      this.currentPage = options.pageNumber

      this.$store.dispatch('categorySystems/fetchCategoryContents', {
        model: this.selectedCategory,
        options: Object.assign(options, {
          sub_categories_included: true
        })
      })
    }
  }
}
