<template lang="pug">
.vc-page-preference-infolink-container(v-show="isShowContainer")
  .odd-container
    k-section-header(:header-info="headerInfo")
  .section-body.info-link
    info-link-use-type-container.odd-container(
      :use-type="useType"
      :is-slider="false"
    )
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'skid-composables'
import KSectionHeader from '@applicationComponents/common/k-section-header.vue'
import InfoLinkUseTypeContainer from '@applicationComponents/info_link/use-type-container.vue'

interface Data {
  title: String
  title_en: String
  subtitle: String
  use_type: String
}

// props
const props = defineProps<{
  data: Data
}>()

// data

const store = useStore()

// computed
/**
 * @returns {computedRef<Object>}
 */
const headerInfo = computed<Object>(() => {
  return {
    title: props.data.title,
    titleEn: props.data.title_en,
    subtitle: props.data.subtitle
  }
})
/**
 * @returns {computedRef<String>}
 */
const useType = computed<String>(() => {
  return props.data.use_type
})
/**
 * @returns {computedRef<Array<Object>>}
 */
const infoLinks = computed<Array<Object>>(() => {
  return store.getters['infoLinks/allByType'](useType.value)
})
/**
 * @returns {computedRef<Boolean>}
 */
const isShowContainer = computed<Boolean>(() => {
  return infoLinks.value.length > 0
})
</script>
