import { defineComponent } from 'vue'
import BackgroundSlide from '../../components/common/background-slide.vue'
import JoinsUsCards from '../../components/joins_us/cards.vue'
import PartnerLoginButton from '@applicationComponents/common/partner-login-button.vue'
import Slogan from '@application/images/register-slogan.svg'

export default defineComponent({
  name: 'joins-us-page-container',

  components: {
    BackgroundSlide,
    JoinsUsCards,
    PartnerLoginButton
  },

  // mixins: [],

  // props: {},

  // setup() {
  //   return {}
  // }

  // data() {
  //   return {}
  // },

  computed: {
    slogan() {
      return Slogan
    }
  }

  // created() {},

  // mounted() {},

  // methods: {}
})
