import union from 'lodash.union'
import intersection from 'lodash.intersection'

export default () => {
  const createShipmentsFromCartItems = ({ cartItemDataArray }) => {
    const cartItemDataSet = cartItemDataArray.map((data) => {
      return {
        cartItemId: data.cartItemId,
        shippingMethodIds: data.shippingMethodIds,
        partnerId: data.partnerId
      }
    })
    const shipments = []

    const partnerDataSet = splitCartItemDataSetByPartnerId({ cartItemDataSet })

    partnerDataSet.forEach((partnerData) => {
      const partnerId = partnerData.partnerId
      const cartItemDataSet = partnerData.cartItemDataSet

      // 如果有交集，則選擇交集

      const intersectionShippingMethodIds = intersection(
        ...cartItemDataSet.map((cartItemData) => cartItemData.shippingMethodIds)
      )

      if (intersectionShippingMethodIds.length > 0)
        return shipments.push({
          partner_id: partnerId,
          selectable_shipping_method_ids: intersectionShippingMethodIds,
          shipping_method_id: intersectionShippingMethodIds[0],
          items: cartItemDataSet.map((data) => {
            return { item_id: data.cartItemId }
          })
        })

      // 如果沒有交集，則尋找多筆 shippingMethod 配對組合

      const matchShippingMethodCombination = findMatchShippingMethodCombination(
        {
          cartItemDataSet: cartItemDataSet
        }
      )

      return matchShippingMethodCombination.map((shippingMethodData) => {
        shipments.push({
          partner_id: partnerId,
          selectable_shipping_method_ids: [
            shippingMethodData.shipping_method_id
          ],
          shipping_method_id: shippingMethodData.shipping_method_id,
          items: shippingMethodData.cartItemDataSet.map((data) => {
            return { item_id: data.cartItemId }
          })
        })
      })
    })

    return shipments
  }

  const splitCartItemDataSetByPartnerId = ({ cartItemDataSet }) => {
    const uniqParnterIds = union(cartItemDataSet.map((data) => data.partnerId))

    return uniqParnterIds.map((partnerId) => {
      return {
        partnerId,
        cartItemDataSet: cartItemDataSet.filter((cartItemData) => {
          return cartItemData.partnerId === partnerId
        })
      }
    })
  }

  /**
   * 找尋符合全部商品的運費組合
   *
   * @param {Array} cartItemDataSet - The array of cart items, where each item is an object with keys:
   * @returns {Array}
   *
   * @example
   *  findMatchShippingMethodCombination({
   *    cartItemDataSet: [
   *      { cartItemId: 1, shippingMethodIds: [1] },
   *      { cartItemId: 2, shippingMethodIds: [1,2] },
   *      { cartItemId: 3, shippingMethodIds: [2,3] }
   *    ]
   *  })
   *
   *  Return
   *    [
   *      {
   *        shippingMethodId: 1,
   *        cartItemDataSet: [{ cartItemId: 1, shippingMethodIds: [1] }, { cartItemId: 2, shippingMethodIds: [1,2] }]
   *      },
   *      {
   *        shippingMethodId: 2,
   *        cartItemDataSet: [{ cartItemId: 3, shippingMethodIds: [2,3] }]
   *      }
   *    ]
   */

  const findMatchShippingMethodCombination = ({ cartItemDataSet }) => {
    const uniqShippingMethodIds = union(
      ...cartItemDataSet.map((data) => data.shippingMethodIds)
    )

    const combinations = getCombinations(uniqShippingMethodIds)

    const matchCombinations = findMatchCombinations({
      combinations: combinations,
      cartItemDataSet: cartItemDataSet
    })

    return allocateCartItemsToCombination({
      combination: matchCombinations[0],
      cartItemDataSet: cartItemDataSet
    })
  }

  /**
   * 取得陣列的排列組合
   * @param {array} valuesArray
   * @return {array}
   *
   * @example
   *  getCombinations([1, 2, 3])
   *  Returns [[1], [2], [3], [1, 2], [1, 3], [2, 3], [1, 2, 3]]
   */
  const getCombinations = (valuesArray) => {
    var combi = []
    var temp = []
    var slent = Math.pow(2, valuesArray.length)

    for (var i = 0; i < slent; i++) {
      temp = []
      for (var j = 0; j < valuesArray.length; j++) {
        if (i & Math.pow(2, j)) {
          temp.push(valuesArray[j])
        }
      }
      if (temp.length > 0) {
        combi.push(temp)
      }
    }

    combi.sort((a, b) => a.length - b.length)

    return combi
  }

  /**
   * 找尋符合商品配對的運費組合
   * @param {Array} combinations
   * @param {Array} cartItemDataSet
   * @returns {Array}
   *
   * @examples
   *  findMatchCombinations({
   *    combinations: [[1], [2], [3], [1,2], [2,3], [1,3], [1,2,3]],
   *    cartItemDataSet: [
   *      { cartItemId: 1, shippingMethodIds: [1] },
   *      { cartItemId: 2, shippingMethodIds: [1,2] },
   *      { cartItemId: 3, shippingMethodIds: [2,3] }
   *    ]
   *  })
   *
   *  Returns [[1,2], [1,3], [1,2,3]]
   */

  const findMatchCombinations = ({ combinations, cartItemDataSet }) => {
    return combinations.filter((combination) => {
      return cartItemDataSet.every((cartItemData) => {
        return combination.some((id) => {
          return cartItemData.shippingMethodIds.includes(id)
        })
      })
    })
  }

  /**
   * 依據運費方式，分配對應的商品
   *
   * @param {Array} shippingMethodIds
   * @param {Array} cartItemDataSet
   * @returns {Array}
   *
   * @example
   *  allocateCartItemsToCombination({
   *    shippingMethodIds: [[1, 2]],
   *    cartItemDataSet: [
   *      { cartItemId: 1, shippingMethodIds: [1] },
   *      { cartItemId: 2, shippingMethodIds: [1,2] },
   *      { cartItemId: 3, shippingMethodIds: [2,3] }
   *    ]
   *  })
   *  Return
   *    [
   *      {
   *        shippingMethodId: 1,
   *        cartItemDataSet: [
   *          { cartItemId: 1, shippingMethodIds: [1] },
   *          { cartItemId: 2, shippingMethodIds: [1,2] }
   *        ]
   *      },
   *      {
   *        shippingMethodId: 2,
   *        cartItemDataSet: [
   *          { cartItemId: 3, shippingMethodIds: [2,3] }
   *        ]
   *      }
   *    ]
   */

  const allocateCartItemsToCombination = ({ combination, cartItemDataSet }) => {
    let remainingCartItemDataSet = [...cartItemDataSet]

    return combination.map((shippingMethodId) => {
      const matchCartItemDataSet = remainingCartItemDataSet.filter(
        (cartItmeData) => {
          return cartItmeData.shippingMethodIds.includes(shippingMethodId)
        }
      )

      remainingCartItemDataSet = remainingCartItemDataSet.filter(
        (cartItemData) => {
          return matchCartItemDataSet.find(
            (matchCartItemData) => matchCartItemData.id === cartItemData.id
          )
        }
      )

      return {
        shipping_method_id: shippingMethodId,
        cartItemDataSet: matchCartItemDataSet
      }
    })
  }

  return {
    createShipmentsFromCartItems
  }
}
