<template lang="pug">
.vc-order-shipping-address-home-form.vc-order-address-form
  .section-label
    //- span {{ copyLocaleText('home_information') }}
    span {{ shippingAddressHelper.partner.value.name }}
    span {{ shippingAddressHelper.shippingMethod.value.name }}

  .section-label
    span
      b-checkbox(
        v-if="shipmentForm.id === firstSameDestinationShipmentForm.id"
        type="is-odd"
        v-model="shipmentForm.is_same_as_billing_address"
      ) {{ attributeLocaleText('order', 'is_same_as_billing_address') }}

      b-checkbox(
        v-if="shipmentForm.id != firstSameDestinationShipmentForm.id"
        type="is-odd"
        :true-value="firstSameDestinationShipmentForm.id"
        :fale-value="null"
        v-model="shipmentForm.same_address_shipment_id"
      ) {{ `與${shippingAddressHelper.firstDestinationPartner.value.name} ${shippingAddressHelper.firstDestinationShippingMethod.value.name} 相同` }}

    common-address-button(
      v-if="commonAddressHelper.isCommonAddresButtonShow.value"
      :address="shipmentForm.address"
      :shipping-type="shippingAddressHelper.shippingType.value"
      @selected-address="commonAddressHelper.selectCommonAddressHandler"
    )

  .form-section.has-sub-fields
    template(v-if="shipmentForm.is_same_as_billing_address")
      b-field.is-full(
        :label="attributeLocaleText('order', 'is_same_as_billing_address')"
      )

    template(v-if="shipmentForm.same_address_shipment_id")
      b-field.is-full(
        :label="`收件資料與 ${shippingAddressHelper.firstDestinationPartner.value.name} ${shippingAddressHelper.firstDestinationShippingMethod.value.name} 相同`"
      )

    template(
      v-if="!shipmentForm.same_address_shipment_id && !shipmentForm.is_same_as_billing_address"
    )
      b-field(
        :label="attributeLocaleText('user/common_address', 'recipient')"
        :type="errors.errorClassAt('addr_recipient')"
        :message="errors.get('addr_recipient')"
      )
        b-input(
          type="text"
          name="name"
          autocomplete="name"
          v-model="shipmentForm.address.recipient"
          @input="errors.clear('addr_recipient')"
        )
      b-field(
        :label="attributeLocaleText('user/common_address', 'phone')"
        :type="errors.errorClassAt('addr_phone')"
        :message="errors.get('addr_phone')"
      )
        b-input(
          type="tel"
          autocomplete="tel"
          v-model="shipmentForm.address.phone"
          @input="errors.clear('addr_phone')"
        )

      b-field(
        :label="attributeLocaleText('user/common_address', 'city')"
        :type="errors.errorClassAt('addr_city')"
        :message="errors.get('addr_city')"
      )
        city-select(
          :city.sync="shipmentForm.address.address.city"
          @input="errors.clear('addr_city')"
        )

      b-field(
        :label="attributeLocaleText('user/common_address', 'area')"
        :type="errors.errorClassAt('addr_area')"
        :message="errors.get('addr_area')"
      )
        area-select(
          :city="shipmentForm.address.address.city"
          :area.sync="shipmentForm.address.address.area"
          :zip.sync="shipmentForm.address.address.zip"
          @input="errors.clear('addr_area')"
        )

      b-field.is-full(
        :label="attributeLocaleText('user/common_address', 'street_address')"
        :type="errors.errorClassAt('addr_street_address')"
        :message="errors.get('addr_street_address')"
      )
        b-input(
          type="text"
          v-model="shipmentForm.address.address.street_address"
          autocomplete="street-address"
          @input="errors.clear('addr_street_address')"
        )

      save-address-as-common-field(
        v-if="commonAddressHelper.isSaveAddressAsCommonShow.value"
        :save-address-as-common.sync="shipmentForm.save_shipping_address_as_common"
      )
</template>

<script>
import { defineComponent, computed, ref, onMounted, watch, reactive } from 'vue'
import { useStore, useModal, useInstance } from 'skid-composables'
import CommonAddressButton from './common-address-button.vue'
import SaveAddressAsCommonField from './save-address-as-common-field.vue'
import CitySelect from '@sharedComponents/city-select.vue'
import AreaSelect from '@sharedComponents/area-select.vue'
import useShippingAddressHelper from './shipping-address-helper'
import useCommonAddressHelper from './common-address-helper.js'

export default defineComponent({
  components: {
    CommonAddressButton,
    SaveAddressAsCommonField,
    CitySelect,
    AreaSelect
  },

  props: {
    shipmentForm: {
      type: Object,
      required: true
    },

    firstSameDestinationShipmentForm: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit }) {
    const shippingAddressHelper = useShippingAddressHelper({
      shipmentForm: props.shipmentForm,
      firstSameDestinationShipmentForm: props.firstSameDestinationShipmentForm
    })

    const commonAddressHelper = useCommonAddressHelper({
      addressForm: props.shipmentForm.address,
      isReferenceFromOtherForm: () => {
        return (
          props.shipmentForm.is_same_as_billing_address ||
          props.shipmentForm.same_address_shipment_id
        )
      },
      addressChangedHandler: () => {
        props.shipmentForm.save_shipping_address_as_common = true
      }
    })

    const errors = computed(() => {
      return shippingAddressHelper.shipmentErrors.value
    })

    return {
      shippingAddressHelper,
      commonAddressHelper,
      errors
    }
  }
})
</script>
