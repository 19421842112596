<template lang="pug">
.vc-order-address-info-unit.info-section
  .section-label.is-flex.is-justify-content-space-between
    span.text {{ title }}
    span.text(v-if="title2") {{ title2 }}
  .section-content
    .info-unit(v-if="addressType === 'shipping'")
      .label {{ attributeLocaleText('order', 'email') }}
      .info-content {{ order.email }}
    .info-unit
      .label {{ recipientLabelName }}
      .info-content {{ address.recipient }}
    .info-unit
      .label {{ attributeLocaleText('order/address', 'phone') }}
      .info-content {{ address.fullPhone() }}
    template(v-if="address.hasCompanyInfo()")
      .info-unit
        .label {{ attributeLocaleText('order/address', 'company') }}
        .info-content {{ address.company }}
      .info-unit
        .label {{ attributeLocaleText('order/address', 'ein') }}
        .info-content {{ address.ein }}
    .info-unit.cvs-info(v-if="address.isCvsStoreInfo()")
      .label.title {{ enumLocaleText('shipping_method', 'sub_logistic_type', address.address.extra_data) }}
      .label {{ attributeLocaleText('user/common_address', 'cvs_store_name') }}
      .info-content {{ cvsStoreInfo.cvs_store_name }}
      .label {{ attributeLocaleText('user/common_address', 'cvs_store_id') }}
      .info-content {{ cvsStoreInfo.cvs_store_id }}
      .label {{ attributeLocaleText('user/common_address', 'cvs_telephone') }}
      .info-content {{ cvsStoreInfo.cvs_telephone }}
      .label {{ attributeLocaleText('user/common_address', 'cvs_address') }}
      .info-content {{ cvsStoreInfo.cvs_address }}
    .info-unit.cvs-info(v-if="address.isStoreInfo()")
      .label.title {{ enumLocaleText('shipping_method', 'sub_logistic_type', address.address.extra_data) }}
      .label {{ attributeLocaleText('user/common_address', 'cvs_store_name') }}
      .info-content {{ storeInfo.name }}
      //- .label {{ attributeLocaleText('user/common_address', 'cvs_telephone') }}
      //- .info-content {{ storeInfo.cvs_telephone }}
      .label {{ attributeLocaleText('user/common_address', 'cvs_address') }}
      .info-content {{ address.storeFullAddress() }}
      template(v-if="pickupDate")
        .label {{ attributeLocaleText('order/address', 'pickup_date') }}
        .info-content {{ pickupDate }}
    .info-unit(v-if="address.isNormalAddress() || order.isAnOverseaOrder()")
      .label {{ attributeLocaleText('order/address', 'address') }}
      .info-content {{ address.fullAddress() }}
</template>

<script>
import UserCommonAddress from '../../../../shared/resource_models/user_common_address.js'
import moment from 'moment'

export default {
  // components: {},
  // mixins: [],
  props: {
    order: {
      type: Object,
      required: true
    },

    addressType: {
      type: String,
      required: true,
      validator: (value) => {
        return ['shipping', 'billing'].includes(value)
      }
    },

    orderAddress: {
      type: Object,
      required: true
    },

    title: {
      type: String,
      required: true
    },

    title2: {
      type: String,
      required: false
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    recipientLabelName() {
      return this.attributeLocaleText(
        'user/common_address',
        this.addressType === 'billing' ? 'buyer_name' : 'recipient'
      )
    },

    address() {
      return new UserCommonAddress(this.orderAddress)
    },

    cvsStoreInfo() {
      return this.address.address.cvs_store_info
    },

    storeInfo() {
      return this.address.address.store_info
    },

    pickupDate() {
      if (!this.address.address.pickup_date) return

      return moment.unix(this.address.address.pickup_date).format('YYYY/MM/DD')
    }
  }
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
