<template lang="pug">
.vc-content-hot-contents
  .content-section-header.-border-bottom
    h2.section-title.-justify-left
      | {{ sectionTitle }}
    .tools-wrapper

  .content-section-body
    b-loading(
      :active.sync="isLoading"
      :is-full-page="false"
    )

    .content-list
      content-card(
        v-for="(content, index) in contents"
        :content="content"
        :index="index"
        :key="content.id"
      )
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useI18n, useStore } from 'skid-composables'
import ContentCard from '../content/card.vue'

export interface Props {
  category: Object
  contentsCount?: Number
}

const props = withDefaults(defineProps<Props>(), {
  contentsCount: () => 3
})

const { copyLocaleText } = useI18n()
const store = useStore()

// data
const contentIds = ref([])
const isLoading = ref(true)

// computed
/**
 * @returns {computedRef<string>}
 */
const sectionTitle = computed<String>(() => {
  return copyLocaleText('hot_blogs')
})
/**
 * @returns {computedRef<Array<object>>}
 */
const contents = computed<Array<object>>(() => {
  return contentIds.value.map((id) => store.getters['contents/find'](id))
})

// mounted
onMounted(async () => {
  await _fetchContents()
})

// methods
const _fetchContents = async () => {
  const response = await store.dispatch(
    'categorySystems/fetchCategoryContents',
    {
      model: props.category,
      options: {
        pageNumber: 1,
        pageSize: props.contentsCount,
        sort: '-view_count',
        forSearch: true,
        sub_categories_included: true
      }
    }
  )

  contentIds.value = response.data.data.map((content) => content.id)
  isLoading.value = false
}
</script>
