import PromoterEventProductList from '../../components/promoter_event/product-list.vue'
import PromoterEventStorageService from '../../../../shared/services/promoter_event_storage_service.js'
import DefaultPromoterBgImage from '../../../../application/images/promoter-default-bg-image.jpg'
import MemberCartButton from '../../components/common/member-cart-button.vue'
import useImpressionService from '../../composables/use_impression_service'
import klog from '../../components/blog/card-list.vue'

export default {
  name: 'promoter-event-show-page-container',

  components: {
    PromoterEventProductList,
    MemberCartButton,
    klog
  },

  mixins: [],

  // props: {},

  data() {
    return {
      currentTab: 0,
      promoterEventId: null,
      promoterShareId: null,
      isLoaded: false,
      promoterEventStorageService: null
    }
  },

  computed: {
    tabs() {
      return [
        { label: '商城', component: 'promoter-event-product-list' },
        { label: 'KLog', component: 'klog' }
      ]
    },

    currentComponent() {
      return this.tabs[this.currentTab].component
    },

    promoterEvent() {
      return this.$store.getters['promoterEvents/find'](this.promoterEventId)
    },

    promoterShare() {
      return this.$store.getters['promoterShares/find'](this.promoterShareId)
    },

    promoterShareLevel() {
      return this.$store.getters['promoterShareLevels/findCurrentByShareId'](
        this.promoterShare.id
      )
    },

    promoterLevel() {
      return this.$store.getters['promoterLevels/find'](
        this.promoterShareLevel.level_id
      )
    },

    promoter() {
      return this.$store.getters['promoters/find'](
        this.promoterShare.promoter_id
      )
    },

    promoterMember() {
      return this.$store.getters['promoterMembers/find'](
        this.promoter.promoter_member_id
      )
    },

    isLoading() {
      return !this.isLoaded
    },

    promoterBgImage() {
      return this.promoterMember.bg_image.url || DefaultPromoterBgImage
    },

    promoterPhotoImage() {
      return this.promoterMember.photo.url
    },

    checkOrderPath() {
      return this.promoterShare.checkOrderLink()
    }
  },

  // created() {},

  async mounted() {
    this.promoterEventId = this.$el.dataset.promoterEvent
    this.promoterShareId = this.$el.dataset.promoterShare
    this.promoterEventStorageService = new PromoterEventStorageService(
      this.$vlf
    )

    try {
      await this.$store.dispatch(
        'promoterShares/findWithoutProducts',
        this.promoterShareId
      )

      await this.promoterEventStorageService.savePromoterShareToken(
        this.promoterShare.token
      )

      await this.$store.dispatch(
        'promoters/find',
        this.promoterShare.promoter_id
      )

      await this.$store.dispatch(
        'promoterMembers/find',
        this.promoter.promoter_member_id
      )

      this.isLoaded = true

      await this.tryRestoreScrollPosition()

      this.createImpression()
    } catch (error) {
      this.isLoaded = true
    }
  },

  methods: {
    createImpression() {
      const service = useImpressionService({
        impressionableType: 'Promoter::Share',
        impressionableId: this.promoterShare.id,
        store: this.$store
      })

      service.createImpression()
    }
  }
}
