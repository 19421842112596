<template lang="pug">
.vc-common-background-slide
  .slide-column(v-for="index in slideColumnCount")
    .inner
      img.pic(
        v-for="index in 8"
        :src="pickRandomSlideImage()"
        @load="onImageLoad"
      )
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useUnsplash } from 'skid-composables'

export default defineComponent({
  // components: {},
  // mixins: [],
  props: {
    slideColumnCount: {
      type: Number,
      required: false,
      default: 6
    }
  },

  setup(props) {
    const searchTermsCandidates = ref([
      'sweets',
      'food',
      'cake',
      'thai-food',
      'boba-tea',
      'cupcake',
      'dessert',
      'pastry',
      'cookie',
      'chocolate',
      'ice-cream',
      'steak',
      'meat',
      'pork',
      'beef',
      'lamb',
      'coffee',
      'seafood',
      'yummy',
      'delight',
      'drink',
      'wine',
      'beer',
      'gift',
      'party',
      'gadget',
      'headphone',
      'hamburger',
      'fried-chicken',
      'japanese-food'
    ])
    let selectedTerms = []
    const pickAndRecordRandomSearchTerm = () => {
      const randomIndex = Math.floor(
        Math.random() *
          searchTermsCandidates.value.filter(
            (element) => !selectedTerms.includes(element)
          ).length
      )
      const result = searchTermsCandidates.value[randomIndex]

      selectedTerms.push(result)

      return result
    }

    return {
      // methods
      pickRandomSlideImage() {
        return useUnsplash('420x600', pickAndRecordRandomSearchTerm())
      },
      onImageLoad(event) {
        event.target.classList.add('-is-loaded')
      }
    }
  }
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  // methods: {}
})
</script>
