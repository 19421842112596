<template lang="pug">
a.vc-promotional-article-area-link(
  :class="`area-link-${_uid} ${isLoading ? 'is-loading' : ''}`"
  href="#"
  @click.prevent="showVariantsSelector"
)
  .inner-wrapper
    .product-name
      .icon
        i.mdi.mdi-cart-plus
      span {{ product && product.name }}
    .just-a-line
    .dot
      .dot-in-dot
</template>

<script>
import inView from 'in-view'
import VariantsSelector from '../product/variants-selector.vue'
import SalesEventVariantsSelector from '../sales_event/variants-selector.vue'
import PromoterEventVariantsSelector from '../promoter_event/variants-selector.vue'

export default {
  props: {
    productId: {
      type: String,
      required: true
    },
    salesEventId: {
      type: String,
      required: false
    },
    promoterShareId: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      isLoading: true,
      isNotFound: false
    }
  },

  computed: {
    product() {
      return this.$store.getters['products/find'](this.productId)
    },

    exceptionMessage() {
      if (this.salesEvent.isDataLoaded() && !this.salesEvent.isLive())
        return this.messageLocaleText('help.sales_event_not_live_currently')
      if (!this.product.isPurchasable())
        return this.messageLocaleText(
          'help.sorry_this_product_sold_out_currentlly'
        )
      if (this.isNotFound) return '商品目前無法選購'
    },

    variantSelector() {
      if (this.salesEventId) {
        return SalesEventVariantsSelector
      } else if (this.promoterShareId) {
        return PromoterEventVariantsSelector
      } else {
        return VariantsSelector
      }
    },

    salesEvent() {
      return this.$store.getters['salesEvents/find'](this.salesEventId)
    },

    promoterShare() {
      return this.$store.getters['promoterShares/find'](this.promoterShareId)
    },

    promoterEvent() {
      return (
        this.promoterShare &&
        this.$store.getters['promoterEvents/find'](this.promoterShare.event_id)
      )
    }
  },

  mounted() {
    this.__bindingInViewEvent()
  },

  methods: {
    __bindingInViewEvent() {
      inView(`.area-link-${this._uid}`).once('enter', this.__fetchProduct)
    },

    showVariantsSelector() {
      if (this.isLoading) return

      if (this.exceptionMessage) return this.showMessage()

      this.$buefy.modal.open({
        parent: this,
        width: 380,
        component: this.variantSelector,
        props: {
          product: this.product,
          salesEvent: this.salesEvent,
          promoterShare: this.promoterShare
        }
      })
    },

    showMessage() {
      this.$store.dispatch('addFlashMessage', ['notice', this.exceptionMessage])
    },

    __fetchProduct() {
      if (this.salesEventId) {
        return this.$store
          .dispatch('salesEvents/find', this.salesEventId)
          .then((_) => {
            return this.$store.dispatch('salesEvents/findProduct', {
              model: this.salesEvent,
              productId: this.productId
            })
          })
          .catch((_) => {
            this.isNotFound = true
            this.isLoading = false
          })
          .finally((_) => {
            this.isLoading = false
          })
      } else if (this.promoterShareId) {
        return this.$store
          .dispatch('promoterShares/find', this.promoterShareId)
          .then((_) => {
            return this.$store.dispatch('promoterEvents/findProduct', {
              model: this.promoterEvent,
              productId: this.productId
            })
          })
          .catch((_) => {
            this.isNotFound = true
          })
          .finally((_) => {
            this.isLoading = false
            this.apiCallCount = 0
          })
      } else {
        return this.$store
          .dispatch('products/find', this.productId)
          .catch((_) => {
            this.isNotFound = true
            this.isLoading = false
          })
          .finally((_) => {
            this.isLoading = false
          })
      }
    }
  }
}
</script>
