import { computed, reactive, watch } from 'vue'
import { useStore, useInstance } from 'skid-composables'

export default ({
  addressForm,
  isReferenceFromOtherForm,
  addressChangedHandler
}) => {
  const vueInstance = useInstance()

  const data = reactive({
    isEditedByCommonAddress: true,
    selectedCommonAddress: null,
    isAddressEdited: false
  })

  const isUserSignedIn = computed(() => {
    return vueInstance.isUserSignedIn
  })

  const selectCommonAddressHandler = (commonAddress) => {
    data.selectedCommonAddress = commonAddress
    data.isEditedByCommonAddress = true

    Object.assign(addressForm, {
      recipient: commonAddress.recipient,
      phone: commonAddress.phone,
      company: commonAddress.company,
      ein: commonAddress.ein,
      dial_code: commonAddress.dial_code,
      address: commonAddress.address
    })
  }

  const isCommonAddresButtonShow = computed(() => {
    if (!isUserSignedIn.value) return false

    if (isReferenceFromOtherForm()) return false

    return true
  })

  const isSaveAddressAsCommonShow = computed(() => {
    if (!isUserSignedIn.value) return false

    if (data.selectedCommonAddress) return false

    if (!data.isAddressEdited) return false

    return true
  })

  watch(
    computed(() => addressForm),
    () => {
      data.isAddressEdited = true

      if (isUserSignedIn.value) addressChangedHandler()

      if (data.isEditedByCommonAddress)
        return (data.isEditedByCommonAddress = false)

      data.selectedCommonAddress = null
    },
    { deep: true }
  )

  return {
    data,
    selectCommonAddressHandler,
    isCommonAddresButtonShow,
    isSaveAddressAsCommonShow
  }
}
