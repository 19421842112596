<template lang="pug">
.vc-content-relevant-contents
  .content-section-header.-border-left
    h2.section-title.-justify-left
      | {{ copyLocaleText('relevant_content') }}
    .tools-wrapper
      //- .icon
      //-   i.fa.fa-chevron-right
  .content-section-body
    content-card(
      v-for="content in contents"
      :key="content.id"
      :content="content"
    )
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'skid-composables'
import ContentCard from './card.vue'

const props = defineProps<{
  content?: Object
  defaultUsages?: Array<string>
}>()

// data
const contentIds = ref([])
const isLoading = ref(true)

const store = useStore()

// computed
/**
 * @returns {computedRef<Array>}
 */
const contents = computed<Array<object>>(() => {
  return contentIds.value.map((id) => store.getters['contents/find'](id))
})

// data
onMounted(async () => {
  await _fetchContents()
})

// methods
/**
 * @returns {void}
 */
const _fetchContents = async () => {
  const response = await store.dispatch('contents/all', {
    pageNumber: 1,
    pageSize: 6,
    sort: '-published_at',
    forSearch: true,
    search: {
      id_not_eq: props.content.id,
      category_id_eq: props.content.category.id
    }
  })

  contentIds.value = response.data.data.map((content) => content.id)
  isLoading.value = false
}
</script>
