<template lang="pug">
.vc-content-latest-contents
  .content-section-header
    .icon
      i.mdi.mdi-comment-alert
    h2.section-title.-justify-left
      | {{ copyLocaleText('latest_content') }}
    .tools-wrapper
      .icon
        i.fa.fa-chevron-right
  .content-section-body
    b-loading(
      :active.sync="isLoading"
      :is-full-page="false"
    )

    content-card.extra-small(
      v-for="content in contents"
      :key="content.id"
      :content="content"
    )
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'skid-composables'
import ContentCard from './card.vue'

const props = defineProps<{
  content?: Object
  defaultUsages?: Array<string>
}>()

// data
const contentIds = ref([])
const isLoading = ref(true)

const store = useStore()

// computed
/**
 * @returns {computedRef<Array<object>>}
 */
const contents = computed(() => {
  return contentIds.value.map((id) => store.getters['contents/find'](id))
})

// mounted
onMounted(async () => {
  await _fetchContents()
})

// methods
/**
 * @returns {void}
 */
const _fetchContents = async () => {
  const response = await store.dispatch('contents/all', {
    pageNumber: 1,
    pageSize: 4,
    sort: '-published_at',
    forSearch: true,
    by_default_usages: props.defaultUsages,
    search: {
      id_not_eq: props.content.id
    }
  })

  contentIds.value = response.data.data.map((content) => content.id)
  isLoading.value = false
}
</script>
