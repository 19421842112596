export default {
  // components: {},

  // mixins: [],

  // props: {},

  data() {
    return {
      showPassword: false
    }
  },

  // computed: {},

  // mounted() {},

  methods: {
    toggleShowPassword() {
      this.showPassword = !this.showPassword
    }
  }
}
