<template lang="pug">
a.vc-promotional-article-product-name-trigger.trigger(
  href="#"
  @click.prevent="toggleParentClass"
)
  .icon
    i.mdi(:class="displayInfo.iconClass")
  span {{ displayInfo.text }}
</template>

<script>
export default {
  data() {
    return {
      showingProductName: true
    }
  },

  computed: {
    displayInfo() {
      return this.showingProductName
        ? {
            iconClass: 'mdi-close',
            text: '關閉標籤'
          }
        : {
            iconClass: 'mdi-check',
            text: '顯示標籤'
          }
    }
  },

  methods: {
    toggleParentClass() {
      this.showingProductName = !this.showingProductName
      this.$el.parentElement.classList.toggle(
        'is-hidden-product-name',
        !this.showingProductName
      )
    }
  }
}
</script>
