<template lang="pug">
.vc-order-cart-item-shipping-selector
  b-dropdown(
    v-if="mode === 'edit'"
    :value="shippingMethodId"
    @change="changeShipingMethodId"
  )
    .button.is-default(slot="trigger")
      span {{ shippingMethod.name }}
      .icon
        i.fa.fa-caret-down

    b-dropdown-item(
      v-for="shippingMethod in selectableShippingMethods"
      :value="shippingMethod.id"
      :key="shippingMethod.id"
    )
      | {{ shippingMethod.name }}

  span(v-if="mode === 'show'") {{ shippingMethod.name }}
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from 'skid-composables'

export default defineComponent({
  props: {
    shippingMethodId: {
      type: [String, Number],
      required: true
    },

    selectableShippingMethods: {
      type: Array,
      required: true
    },

    mode: {
      type: String,
      required: true
    }
  },

  setup(props, { emit }) {
    const store = useStore()

    const shippingMethod = computed(() => {
      return store.getters['shippingMethods/find'](props.shippingMethodId)
    })

    const changeShipingMethodId = (value) => {
      emit('update:shipping-method-id', value)
    }

    return {
      shippingMethod,
      changeShipingMethodId
    }
  }
})
</script>
