import { computed, defineComponent, onMounted, ref } from 'vue'

import KolnetIcon from '../../../images/kolnet-icon.svg'

export default defineComponent({
  setup() {
    return {
      KolnetIcon
    }
  }
})
