<template lang="pug">
.vc-order-store-selector.vc-order-address-form
  .section-label
    //- span 取貨門市
    span {{ shippingAddressHelper.partner.value.name }}
    span {{ shippingAddressHelper.shippingMethod.value.name }}

  .section-label
    span
      b-checkbox(
        v-if="shipmentForm.id != firstSameDestinationShipmentForm.id"
        type="is-odd"
        :true-value="firstSameDestinationShipmentForm.id"
        :fale-value="null"
        v-model="shipmentForm.same_address_shipment_id"
      ) {{ `與${shippingAddressHelper.firstDestinationPartner.value.name} ${shippingAddressHelper.firstDestinationShippingMethod.value.name} 相同` }}

    common-address-button(
      v-if="commonAddressHelper.isCommonAddresButtonShow.value"
      :address="shipmentForm.address"
      :shipping-type="shippingAddressHelper.shippingType.value"
      @selected-address="commonAddressHelper.selectCommonAddressHandler"
    )

  .form-section.has-sub-fields
    b-field(
      :label="attributeLocaleText('user/common_address', 'recipient')"
      :type="errors.errorClassAt('addr_recipient')"
      :message="errors.get('addr_recipient')"
    )
      b-input(
        type="text"
        name="name"
        autocomplete="name"
        v-model="shipmentForm.address.recipient"
        @input="errors.clear('addr_recipient')"
      )
    b-field(
      :label="attributeLocaleText('user/common_address', 'phone')"
      :type="errors.errorClassAt('addr_phone')"
      :message="errors.get('addr_phone')"
    )
      b-input(
        type="tel"
        autocomplete="tel"
        v-model="shipmentForm.address.phone"
        @input="errors.clear('addr_phone')"
      )
    save-address-as-common-field(
      v-if="commonAddressHelper.isSaveAddressAsCommonShow.value"
      :save-address-as-common.sync="shipmentForm.save_shipping_address_as_common"
    )

    b-field.is-full(
      label="選擇門市"
      :type="errors.errorClassAt('addr_store_info')"
      :message="errors.get('addr_store_info')"
    )
      b-dropdown(v-model="shipmentForm.address.address.store_info")
        template(#trigger="{ active }")
          b-button.store-info(
            :icon-right="active ? 'chevron-up' : 'chevron-down'"
          )
            template(v-if="shipmentForm.address.address.store_info.name")
              .store-name
                | {{ shipmentForm.address.address.store_info.name }}
              .store-address
                | {{ fullAddress(shipmentForm.address.address.store_info.address) }}
            template(v-else)
              .store-name
                | 尚未選擇店面

        b-dropdown-item.store-info(
          v-for="(storeInfo, index) in storeInfos"
          key="index"
          :value="storeInfo"
        )
          .store-name.cell
            | {{ storeInfo.name }}
          .store-address.cell
            | {{ fullAddress(storeInfo.address) }}

        button.button(slot="trigger")

    b-field.is-full(
      v-if="shipmentForm.address.address.store_info && shipmentForm.address.address.store_info.name"
      label="選擇預計取貨日期"
      :type="errors.errorClassAt('addr_pickup_date')"
      :message="errors.get('addr_pickup_date')"
    )
      b-datepicker(
        v-model="data.pickupDate"
        :mobile-native="false"
        :unselectable-dates="unselectablePickupDates"
        @input="errors.clear('addr_pickup_date')"
      )

  //- .info-section
  //-   .store-info(
  //-     v-for="(storeInfo, index) in storeInfos"
  //-     :ket="index"
  //-   )

  //-       b-dropdown-item(
  //-         v-for="(storeInfo, index) in storeInfos"
  //-       )
  //-     b-radio(
  //-       type="is-odd"
  //-       v-model="shipmentForm.address.address.store_info"
  //-       :native-value="storeInfo"
  //-       @input="errors.clear('addr_store_info')"
  //-     )
  //-       .store-name.cell
  //-         | {{ storeInfo.name }}
  //-       .store-address.cell
  //-         | {{ fullAddress(storeInfo.address) }}
</template>

<script>
import { defineComponent, onMounted, reactive, computed, watch } from 'vue'
import { useStore } from 'skid-composables'
import useShippingAddressHelper from './shipping-address-helper'
import CommonAddressButton from './common-address-button.vue'
import SaveAddressAsCommonField from './save-address-as-common-field.vue'
import useCommonAddressHelper from './common-address-helper.js'
import moment from 'moment'

export default defineComponent({
  components: {
    CommonAddressButton,
    SaveAddressAsCommonField
  },
  // mixins: [],
  props: {
    shipmentForm: {
      type: Object,
      required: true
    },

    firstSameDestinationShipmentForm: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit }) {
    const store = useStore()
    const data = reactive({
      pickupDate: props.shipmentForm.address.address.pickup_date
        ? moment.unix(props.shipmentForm.address.address.pickup_date).toDate()
        : null
    })

    const shippingAddressHelper = useShippingAddressHelper({
      shipmentForm: props.shipmentForm,
      firstSameDestinationShipmentForm: props.firstSameDestinationShipmentForm
    })

    const commonAddressHelper = useCommonAddressHelper({
      addressForm: props.shipmentForm.address,
      isReferenceFromOtherForm: () => {
        return (
          props.shipmentForm.is_same_as_billing_address ||
          props.shipmentForm.same_address_shipment_id
        )
      },
      addressChangedHandler: () => {
        props.shipmentForm.save_shipping_address_as_common = true
      }
    })

    const storeInfos = computed(() => {
      return shippingAddressHelper.shippingMethod.value.shipment_type_detail
        .store_infos
    })

    const extraData = computed(() => {
      return shippingAddressHelper.shippingMethod.value.shipment_type_detail
        .shipping_type
    })

    const fullAddress = (address) => {
      return `${address.zip}${address.city}${address.area}${address.street_address}`
    }

    const errors = computed(() => {
      return shippingAddressHelper.shipmentErrors.value
    })

    watch(
      computed(() => props.shipmentForm.address.address.store_info),
      () => {
        data.pickupDate = null
      }
    )

    watch(
      computed(() => data.pickupDate),
      () => {
        props.shipmentForm.address.address.pickup_date = moment(
          data.pickupDate
        ).unix()
      }
    )

    const unselectablePickupDates = (day) => {
      const storeInfo = props.shipmentForm.address.address.store_info

      if (!storeInfo) return moment(day).isBefore(moment(), 'day')

      // find by except dates
      if (
        (storeInfo.except_dates_for_pickup || []).some((unixDate) => {
          return moment.unix(unixDate).isSame(moment(day), 'day')
        })
      )
        return true

      // find by after_days
      const afterDays = storeInfo.after_days_for_pickup || 0

      const startedDate = moment().add(afterDays, 'day')

      if (moment(day).isBefore(moment(startedDate), 'day')) return true

      return false
    }

    return {
      moment,
      data,
      shippingAddressHelper,
      commonAddressHelper,
      storeInfos,
      extraData,
      fullAddress,
      errors,
      unselectablePickupDates
    }
  }

  // watch: {
  //   'form.shipping_address.address.store_info': {
  //     handler(newAddress) {
  //       this.shippingAddress.address.extra_data = this.extraData
  //       this.flagFormAsEdited()
  //     }
  //   }
  // },
  // created() {},
  // mounted() {},
})
</script>
