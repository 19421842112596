<template lang="pug">
.vc-content-category-category-menu
  ul.menu-items
    li.menu-item(
      v-for="usage in usages"
      :key="usage.id"
    )
      b-dropdown(
        :triggers="isMobile ? ['click'] : ['click', 'hover']"
        aria-role="list"
        hoverable
      )
        template(#trigger)
          a.link(:href="categoryUrl(usage)")
            span.text
              | {{ usage.name }}
            span.icon.is-small
              i.fa.fa-chevron-down(aria-hidden="true")
        b-dropdown-item(
          v-for="subCategory in childrenOf(usage)"
          :key="subCategory.id"
          aria-role="menuitem"
          has-link
        )
          a(:href="categoryUrl(subCategory)")
            span {{ subCategory.name }}
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useStore } from 'skid-composables'

const MOBILE_MENU_BREAK_POINT = 769

// 使用 defineProps 來定義 properties
const props = defineProps<{
  defaultUsages?: Array<string>
}>()

const store = useStore()

// computed
/**
 * @returns {computedRef<boolean>}
 */
const isMobile = computed<Boolean>(() => {
  return (
    (store.getters['windowWidth'] || window.innerWidth) <
    MOBILE_MENU_BREAK_POINT
  )
})
/**
 * @returns {computedRef<array>}
 */
const usages = computed<Array<object>>(() => {
  return store.getters['categorySystems/allCategories']({
    depth: 1,
    defaultUsages: props.defaultUsages
  })
})

// mounted
onMounted(async () => {
  await _ensureCategoriesLoaded()
})

// methods
/**
 * @returns {void}
 */
const _ensureCategoriesLoaded = async () => {
  await store.dispatch('categorySystems/fetchCategoriesByType', {
    type: 'content',
    sort: 'created_at'
  })
}
/**
 * @returns {Array}
 */
const childrenOf = (category) => {
  return store.getters['categorySystems/childrenOf'](category).sort(
    (a, b) => a.position - b.position
  )
}
/**
 * @returns {String}
 */
const categoryUrl = (category) => {
  if (
    ['about', 'terms_and_policies'].every((usage) =>
      props.defaultUsages.includes(usage)
    )
  )
    return `/abouts/${category.id}/contents`

  return `/news-posts/${category.id}/contents`
}
</script>
