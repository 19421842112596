import SlideshowContainer from '../components/common/slideshow-container.vue'
import HotProducts from '../components/product/hot-products.vue'
import SelectProducts from '../components/product_category/select-products.vue'
import MobileQuickLinkWrapper from '../components/common/mobile-quick-link-wrapper.vue'
import InfoLinkUseTypeContainer from '../components/info_link/use-type-container.vue'
import KCarouselAdsModal from '@ksharedComponents/modal/k-carousel-ads-modal.vue'
import JoinsUsCards from '../components/joins_us/cards.vue'

export default {
  name: 'landing-page-container',

  components: {
    SlideshowContainer,
    HotProducts,
    SelectProducts,
    MobileQuickLinkWrapper,
    InfoLinkUseTypeContainer,
    KCarouselAdsModal,
    JoinsUsCards
  },

  // mixins: [],

  // props: {},

  // data() {
  //   return {}
  // },

  computed: {
    displayConfig() {
      return (
        this.$store.getters['siteConfigs/siteCustomDisplayConfig'].page_index ||
        {}
      )
    }
  },

  // created() {},

  async mounted() {
    await this.tryRestoreScrollPosition()
  }

  // methods: {}
}
