import { computed, reactive, watch } from 'vue'
import { useStore, useInstance } from 'skid-composables'

export default ({ shipmentForm, firstSameDestinationShipmentForm }) => {
  const store = useStore()
  const vueInstance = useInstance()
  const data = reactive({
    isEditedByCommonAddress: true,
    selectedCommonAddress: null,
    isFormEdited: false
  })

  const isUserSignedIn = computed(() => {
    return vueInstance.isUserSignedIn
  })

  const shipment = computed(() => {
    return store.getters['orderShipments/find'](shipmentForm.id)
  })

  const partner = computed(() => {
    return store.getters['partners/find'](shipment.value.partner_id)
  })

  const order = computed(() => {
    return store.getters['orders/find'](shipment.value.order.id)
  })

  const shippingMethod = computed(() => {
    return store.getters['shippingMethods/find'](
      shipment.value.shipping_method.id
    )
  })

  const shippingType = computed(() => {
    return shippingMethod.value.shipment_type_detail.shipping_type
  })

  // errors
  const errors = computed(() => store.getters['users/errors'])

  const shipmentErrors = computed(() => {
    const shipmentErrors = errors.value.get('shipments')

    if (!shipmentErrors) return shipment.value.errors

    const shipmentError = shipmentErrors.find((error) => {
      return JSON.parse(error).id === parseInt(shipment.value.id)
    })

    if (!shipmentError) return shipment.value.errors

    shipment.value.errors.record({
      response: {
        status: 422,
        data: {
          validation_errors: JSON.parse(shipmentError)
        }
      }
    })

    return shipment.value.errors
  })

  // first same destination shipment
  const firstSameDestinationShipment = computed(() => {
    return store.getters['orderShipments/find'](
      firstSameDestinationShipmentForm.id
    )
  })

  const firstDestinationShippingMethod = computed(() => {
    return store.getters['shippingMethods/find'](
      firstSameDestinationShipment.value.shipping_method.id
    )
  })

  const firstDestinationPartner = computed(() => {
    return store.getters['partners/find'](
      firstSameDestinationShipment.value.partner_id
    )
  })

  return {
    data,

    order,
    shipment,
    partner,
    shippingMethod,
    shippingType,

    // errors
    shipmentErrors,

    // first same destination shipment
    firstSameDestinationShipment,
    firstDestinationShippingMethod,
    firstDestinationPartner
  }
}
