import moment from 'moment'
import FormBase from 'odd-form_object'

export default class PlaceOrderForm extends FormBase {
  constructor(model) {
    const attribues = Object.assign(model, {
      is_same_as_billing_address: true,
      billing_address: model.billing_address,
      is_agree_with_term_of_service: true,
      is_agree_to_receive_news_letters: true,
      save_shipping_address_as_common: false,
      save_billing_address_as_common: false
    })

    super(attribues)

    if (!this.billing_address) this.billing_address = this._addressTemplate()

    this.shipments = this._initShipments(model.shipments)
  }

  _addressTemplate() {
    return {
      recipient: '',
      dial_code: '',
      phone: '',
      company: '',
      ein: '',
      address: {
        city: '',
        area: '',
        street_address: '',
        zip: '',
        cvs_store_info: {},
        store_info: {},
        extra_data: 'billing'
      }
    }
  }

  _initShipments(shipments) {
    return shipments.map((shipment) => {
      const firstSameDestinationShipment = shipments.find((s) => {
        return s.shipping_destination === shipment.shipping_destination
      })

      return {
        id: shipment.id,
        shipping_destination: shipment.shipping_destination,
        same_address_shipment_id:
          firstSameDestinationShipment.id === shipment.id
            ? null
            : firstSameDestinationShipment.id,
        is_same_as_billing_address: false,
        save_shipping_address_as_common: false,
        address: {
          recipient: shipment.address?.recipient,
          dial_code: shipment.address?.dial_code,
          phone: shipment.address?.phone,
          company: shipment.address?.company,
          ein: shipment.address?.ein,
          address: {
            city: shipment.address?.address?.city || '',
            area: shipment.address?.address?.area || '',
            street_address: shipment.address?.address?.street_address || '',
            zip: shipment.address?.address?.zip || '',
            store_info: shipment.address?.address?.store_info || {},
            pickup_date: shipment.address?.address?.pickup_date,
            cvs_store_info: shipment.address?.address?.cvs_store_info || {},
            extra_data: shipment.gateway_info.shipping_type
          }
        }
      }
    })
  }
}
