<script>
import ProductCard from '../promotional_article/product-card.vue'
import ProductLink from '../promotional_article/product-link.vue'
import AreaLink from '../promotional_article/area-link.vue'
import ProductNameTrigger from '../promotional_article/product-name-trigger.vue'

export default {
  components: {
    ProductCard,
    ProductLink,
    AreaLink,
    ProductNameTrigger
  },
  // mixins: [],
  props: {
    // For Blog Show Page Only
    content: {
      type: Object,
      required: false
    },

    defaultUsages: {
      type: Array,
      requried: false
    }
  },

  // data() {
  //   return {}
  // },

  computed: {
    categoryUrl() {
      if (
        ['about', 'terms_and_policies'].every((usage) =>
          this.defaultUsages.includes(usage)
        )
      )
        return `/abouts/${this.content.category.id}/contents`

      return `/news-posts/${this.content.category.id}/contents`
    }
  }
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
