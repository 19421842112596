<template lang="pug">
.vc-promoter-event-purchase-button.option-button
  .button.is-odd(
    @click="clickHandler"
    :class="soldOutClass"
  )
    .icon
      i.fa.fa-shopping-cart
    template(v-if="isProductPurchaseable")
      span.text {{ actionLocaleText('select_goods') }}
    template(v-else)
      span.text {{ copyLocaleText('stock_status.no_stock') }}
</template>

<script>
import ActionConfirmService from '@services/action_confirm_service.js'
import productPropsMixin from '../mixins/product_props_mixin.js'
import VariantsSelector from './variants-selector.vue'

export default {
  components: {
    VariantsSelector
  },

  mixins: [productPropsMixin],

  props: {
    promoterShare: {
      type: Object,
      required: true
    }
  },

  // data() {
  //   return {}
  // },

  computed: {
    soldOutClass() {
      return {
        'is-sold-out': !this.isProductPurchaseable
      }
    },

    promoterEvent() {
      return (
        this.promoterShare &&
        this.$store.getters['promoterEvents/find'](this.promoterShare.event_id)
      )
    },

    isProductPurchaseable() {
      if (this.shareProductShip && this.shareProductShip.is_sold_out)
        return false

      // to-do 未考慮 is_allow_backorder 情境
      return !this.eventProductShip.is_sold_out
    },

    eventProductShip() {
      return this.$store.getters['promoterEventProductShips/all'].find(
        (eventProductShip) => {
          return eventProductShip.product_id === parseInt(this.product.id)
        }
      )
    },

    shareProductShip() {
      return this.$store.getters['promoterShareProductShips/all'].find(
        (shareProductShip) => {
          return shareProductShip.product_id === parseInt(this.product.id)
        }
      )
    }
  },

  // created() {},
  // mounted() {},
  methods: {
    clickHandler() {
      if (!this.isProductPurchaseable) return this.showSoldOutMessage()
      if (this.product.is_age_restricted) {
        return new ActionConfirmService({
          title: this.messageLocaleText('we_have_to_confirm_your_age_first'),
          text: this.messageLocaleText('this_is_adult_only_product'),
          confirmButtonText: this.actionLocaleText('i_am_adult'),
          cancelButtonText: this.actionLocaleText('i_am_not_adult')
        }).confirm(this._showVariantsSelector)
      }
      this._showVariantsSelector()
    },

    _showVariantsSelector() {
      this.$buefy.modal.open({
        parent: this,
        width: 380,
        component: VariantsSelector,
        props: {
          promoterEvent: this.promoterEvent,
          promoterShare: this.promoterShare,
          product: this.product,
          index: this.index,
          listLocation: this.listLocation
        }
      })
    },

    showSoldOutMessage() {
      this.$store.dispatch('addFlashMessage', [
        'notice',
        this.messageLocaleText('help.sorry_this_product_sold_out_currentlly')
      ])
    }
  }
}
</script>
