import ShoppingCartService from '../../../shared/services/shopping_cart_service.js'
import CheckoutStepsParseService from '../../../shared/services/checkout_steps_parse_service.js'
import CheckoutSteps from '../components/order/checkout-steps.vue'
import CartConfirmation from '../components/order/cart-confirmation.vue'
import ShippingAndPaymentMethod from '../components/order/shipping-and-payment-method.vue'
import FinalConfirmation from '../components/order/final-confirmation.vue'

export default {
  name: 'cart-page-container',

  components: {
    CheckoutSteps,
    CartConfirmation,
    ShippingAndPaymentMethod,
    FinalConfirmation
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      currentStep: 0,
      originalStep: 0,
      cartService: null,
      isCurrentOrderLoading: false,
      isProductsLoaded: false
    }
  },

  computed: {
    cartItems() {
      return this.$store.getters['orderItems/fromCurrentOrder']()
    },

    currentOrder() {
      return this.$store.getters['orders/find'](
        this.$store.getters['orderItems/currentOrderId']
      )
    },

    componentOfCurrentStep() {
      const steps = [
        null,
        'cart-confirmation',
        'shipping-and-payment-method',
        'final-confirmation'
      ]

      return steps[this.currentStep]
    },

    variants() {
      return this.$store.getters['productVariants/all']
    },

    cartVariants() {
      return this.cartItems.map((cartItem) => {
        return this.variants.find(
          (variant) => variant.id === cartItem.variant_id
        )
      })
    },

    products() {
      return this.$store.getters['products/all']
    },

    partners() {
      return this.$store.getters['partners/all']
    }
  },

  watch: {
    cartItemsCount(newValue) {
      if (newValue === 0) Turbolinks.visit('/')
    },

    currentOrder: {
      handler(value) {
        let step = CheckoutStepsParseService.call(value)
        this.currentStep = step
        this.originalStep = step
        let updatesInfo = value.updates_info || []
        if (updatesInfo.length > 0) {
          updatesInfo.forEach((info) => {
            this.$store.dispatch('addFlashMessage', info)
          })
        }
      }
    }
  },

  async created() {
    this.cartService = new ShoppingCartService(this.$vlf, this.$store)
    this.isCurrentOrderLoading = true

    try {
      await this.cartService.fetchCurrentOrder({
        force: true
      })

      if (this.cartItems.length === 0) {
        const path = await this.$vlf.getItem('redirect-path')

        Turbolinks.visit(
          (path == 'undefined' ? null : path) || '/?cart_empty=1'
        )
        this.$store.dispatch('addFlashMessage', [
          'notice',
          this.messageLocaleText('cart_is_empty_now')
        ])
      } else {
        await this.fetchProducts()
      }

      this.isCurrentOrderLoading = false
      this.isProductsLoaded = true
    } catch (error) {
      this.isCurrentOrderLoading = false
    }
  },

  // mounted() {},

  methods: {
    fetchProducts() {
      return this.$store.dispatch('orders/fetchProducts', this.currentOrder)
    }
  }
}
