<template lang="pug">
button.button(
  :class="buttonClass"
  @click="clickHandler"
  @click.prevent="clickPreventHandler"
)
  slot
</template>

<script>
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return ['is-info', 'is-primary'].includes(value)
      }
    }
  },

  setup(props, { emit }) {
    const buttonClass = computed(() => {
      switch (props.type) {
        case 'is-info':
          return 'is-info'
        case 'is-primary':
          return 'is-primary'
      }
    })

    const clickHandler = (event) => {
      emit('click', event)
    }

    const clickPreventHandler = (event) => {
      emit('click.prevent', event)
    }

    return {
      buttonClass,
      clickHandler,
      clickPreventHandler
    }
  }
})
</script>
