import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import { useStore } from 'skid-composables'
import liff from '@line/liff'
import KolnetIcon from '../../images/kolnet-icon.svg'

export default defineComponent({
  setup() {
    const container = ref(null)
    const store = useStore()
    const data = reactive({
      message: null,
      liffId: null,
      redirectUrl: null,
      isSkipLogin: null
    })

    // window.liff = liff // for debug

    const initLiffAndSubmitForm = async () => {
      data.message = '轉址中...'

      await liff.init({
        liffId: data.liffId
      })

      // 在 LINE app 的 LIFF 網頁上，LINE 預設是登入狀態，可直接取得 id_token
      // 如果不是在 LIFF 頁面，則需登入

      const idToken = await liff.getIDToken()

      if (!idToken) {
        if (data.isSkipLogin) {
          return (window.location.href = data.redirectUrl)
        }

        return liff.login() // redirect to LINE login page and redirect back to this page
      }

      submitForm(idToken)
    }

    const submitForm = (idToken) => {
      const input = window.document.getElementById('id_token')
      input.value = idToken

      const form = window.document.getElementById('liff-form')
      form.submit()
    }

    onMounted(() => {
      data.liffId = container.value.dataset.liff
      data.isSkipLogin = container.value.dataset.isSkipLogin
      data.redirectUrl = container.value.dataset.redirectUrl

      initLiffAndSubmitForm()
    })

    return {
      KolnetIcon,

      container,

      data
    }
  }
})
