<template lang="pug">
.vc-page-preference-homepage-container
  section(
    v-for="(block, index) in homepagePreferenceBlocks"
    :key="index"
  )
    div(
      :is="renderComponent(block.type)"
      :data="block.data"
    )
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useStore } from 'skid-composables'
import SlideshowContainer from './slideshow-container.vue'
import InfoLinkContainer from './infolink-container.vue'
import SelectSponsorsContainer from './select-sponsors-container.vue'
import SelectSalesEventsContainer from './select-sales-events-container.vue'
import SelectCategoriesContainer from './select-categories-container.vue'
import PartnerPlanContainer from './partner-plan-container.vue'
import CustomerSuccessDepartmentContainer from './customer-success-department-container.vue'

const COMPONENT_MAP = {
  banner: SlideshowContainer,
  infoLink: InfoLinkContainer,
  promoterShare: SelectSponsorsContainer,
  salesEvent: SelectSalesEventsContainer,
  productCategory: SelectCategoriesContainer,
  partnerPlan: PartnerPlanContainer,
  customerSuccessDepartment: CustomerSuccessDepartmentContainer
}

// data
const store = useStore()

// computed
/**
 * @returns {computedRef<Object>}
 */
const homepagePreferenceBlocks = computed<Object>(() => {
  return store.getters['siteConfigs/fetchCustomPagePreferences']
    ?.homepage_settings?.content?.blocks
})

// mounted
onMounted(async () => {
  await store.dispatch('siteConfigs/fetchCustomPagePreferences')
})

// methods
/**
 * @returns {Object}
 */
const renderComponent = (type) => {
  return COMPONENT_MAP[type]
}
</script>
