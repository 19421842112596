<template lang="pug">
.vc-product-info-card-mobile.is-mobile-only(v-if="product.isDataLoaded()")
  .subscription-tag(v-if="product.is_subscription")
    | {{ attributeLocaleText('product', 'subscription_products') }}
  .product-info
    a.brand(
      :href="brandLink"
      v-if="product.brand_id"
    ) {{ product.brand_name }}
    p.name {{ product.name }}

  .price-info
    //- .label
    //-   span {{ attributeLocaleText('product', 'price') }}
    .price
      span(
        v-if="product.hasDiscount()"
        :data-currency="currentCurrency"
      )
        | {{ toMoney(product.original_price).format() }}
      span(:data-currency="currentCurrency")
        | {{ toMoney(product.consumer_price).format() }}
  .sticky-action-bar
    collect-button(:product="product")
    purchase-button(:product="product")
</template>

<script>
import CollectButton from './collect-button.vue'
import PurchaseButton from './purchase-button.vue'
import Countdown from '@chenfengyuan/vue-countdown'

export default {
  components: {
    CollectButton,
    PurchaseButton,
    Countdown
  },
  // mixins: [],
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    brandLink() {
      return `/brands/${this.product.brand_id}`
    }
  }
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
