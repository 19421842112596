<template lang="pug">
.vc-page-preference-slideshow-container
  .odd-container
    slideshow-container(
      :class="useType"
      :use-type="useType"
    )
</template>

<script setup lang="ts">
import { computed } from 'vue'
import SlideshowContainer from '@applicationComponents/common/slideshow-container.vue'

interface Data {
  use_type: String
}

// props
const props = defineProps<{
  data: Data
}>()

// computed
/**
 * @returns {computedRef<String>}
 */
const useType = computed(() => {
  return props.data.use_type
})
</script>
