<template lang="pug">
section.vc-content-section(v-if="contentIds.length > 0")
  .content-section-header
    .odd-container
      h2.section-title.-larger
        | {{ usage.name }}
  .content-section-body
    b-loading(:active.sync="isLoading")

    .odd-container
      .half
        content-card.large(
          :key="firstContent.id"
          :content="firstContent"
        )
      .half
        content-card.small(
          v-for="content in sideContents"
          :key="content.id"
          :content="content"
        )
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'skid-composables'
import ContentCard from './card.vue'

const props = defineProps<{
  usage: Object
}>()

// data
const contentIds = ref([])
const isLoading = ref(true)

const store = useStore()

// computed
/**
 * @returns {computedRef<array>}
 */
const contents = computed<Array<object>>(() => {
  return contentIds.value.map((id) => store.getters['contents/find'](id))
})
/**
 * @returns {computedRef<object>}
 */
const firstContent = computed<object>(() => {
  return contents.value[0]
})
/**
 * @returns {computedRef<array>}
 */
const sideContents = computed<Array<object>>(() => {
  return contents.value.slice(1, 4)
})

// mounted
onMounted(async () => {
  await _fetchContentsByUsage()
})

// methods
/**
 * @returns {void}
 */
const _fetchContentsByUsage = async () => {
  const response = await store.dispatch(
    `categorySystems/fetchCategoryContents`,
    {
      model: props.usage,
      options: {
        pageNumber: 1,
        pageSize: 4,
        sort: '-published_at',
        sub_categories_included: true
      }
    }
  )

  contentIds.value = response.data.data.map((content) => content.id)
  isLoading.value = false
}
</script>
