<template lang="pug">
.vc-page-preference-select-sales-events-container(v-show="isShowContainer")
  .odd-container
    k-section-header(:header-info="headerInfo")
  .section-body
    k-select-sales-events.odd-container(:sales-event-ids="salesEventIds")
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'skid-composables'
import KSectionHeader from '@applicationComponents/common/k-section-header.vue'
import KSelectSalesEvents from '@applicationComponents/sales_event/k-select-events.vue'

interface Data {
  title: String
  title_en: String
  subtitle: String
  sales_event_ids: Array<String>
}

// props
const props = defineProps<{
  data: Data
}>()

const store = useStore()

// computed
/**
 * @returns {computedRef<Object>}
 */
const headerInfo = computed<Object>(() => {
  return {
    title: props.data.title,
    titleEn: props.data.title_en,
    subtitle: props.data.subtitle
  }
})
/**
 * @returns {computedRef<Array<String>>}
 */
const salesEventIds = computed<Array<String>>(() => {
  return props.data.sales_event_ids
})
/**
 * @returns {computedRef<Array<Object>>}
 */
const salesEvents = computed<Array<Object>>(() => {
  return store.getters['salesEvents/all'].filter((salesEvent) => {
    return salesEventIds.value.includes(salesEvent.id)
  })
})
/**
 * @returns {computedRef<Boolean>}
 */
const isShowContainer = computed<Boolean>(() => {
  return salesEvents.value.length > 0
})
</script>
